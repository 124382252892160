<template>
  <div class="first">
    <!-- <div class="wrapper"> -->


    <!-- <div class="index_nav" :class="{index_nav_top:isTop}"> -->
    <!-- <ul> -->
    <!-- <li index="3">
          <div style="height: 20px;width: 100%;">
            <span>注册</span>
          </div>
          <div :class="{navSelect:selectedIndex === 3}" class="selected_marker_container_left">
            <div class="selected_marker selected_marker_right"></div>
          </div>
          <div class="selected_marker_container_right">
            <div class="selected_marker selected_marker_left"></div>
          </div>
        </li>
        <li index="2">
          <div style="height: 20px;width: 100%;">
            <span>|</span>
          </div>
        </li> -->
    <!-- <li index="1" v-if="ifLogin" @click="loginHandler">
          <div style="height: 20px;width: 100%;">
            <span>{{loginChanges}}</span>
          </div>
          <div :class="{navSelect:selectedIndex === 1}" class="selected_marker_container_left">
            <div class="selected_marker selected_marker_right"></div>
          </div>
          <div class="selected_marker_container_right">
            <div class="selected_marker selected_marker_left"></div>
          </div>
        </li>
         <li index="1" v-if="!ifLogin"  @click="goBackstage">
          <div style="height: 20px;width: 100%;">
            <span>进入后台</span>
          </div>
          <div :class="{navSelect:selectedIndex === 1}" class="selected_marker_container_left">
            <div class="selected_marker selected_marker_right"></div>
          </div>
          <div class="selected_marker_container_right">
            <div class="selected_marker selected_marker_left"></div>
          </div>
        </li>
      </ul> -->
    <!-- <div style="clear: both;"></div> -->
    <!-- </div> -->

    <div class="menuNavigation">
      <el-row :gutter="0">
        <el-col :span="2" class="imgLogo" :offset="5" >
          <span class="logon_tit" @click="switching(1)" style="cursor: pointer">
            <img src="../../assets/login/logo1.png" />
          </span>
        </el-col>
        <el-col :span="2" class="imgLogo" :offset="0">
          <span class="shop_logo">
            <el-link :underline="false"
              href="https://qingniaoxiaofangjj.tmall.com/shop/view_shop.htm?spm=a220m.1000862.1000730.2.343b79795vOoTN&user_number_id=4246937270&rn=68cadaf1f53ba62207b7e4e42ae9da30"
              target="_blank"><img @mouseover="mouseOver" @mouseleave="mouseLeave" :src="tianmaopng" /></el-link>
            <!-- <el-link :underline="false" href="https://mall.jd.com/index-11519415.html?from=pc" target="_blank"><img src="../../assets/login/jd.svg" /></el-link>   -->

            <el-link href="https://mall.jd.com/index-11496243.html?from=pc" :underline="false" target="_blank">
              <img @mouseover="mouseOverJd" @mouseleave="mouseLeaveJd" :src="jingdongpng"></el-link>
            <!-- <el-link :underline="false" href="https://wenshengznjj.tmall.com/shop/view_shop.htm?spm=a220m.1000862.1000725.3.7c3e6b75J2HrCG&user_number_id=2212378198169&rn=499f91d77e9c1cad1351e8e61c036217" target="_blank">  <img src="../../assets/login/tm.svg" /></el-link>   -->
            <!--  -->
          </span>
        </el-col>
        <el-col :span="10">
          <div class="menu_list">
            <!-- <img src="../../assets/login/logo.png" style="width: 50px;height: 50px;margin-top: 10px;" /> -->
            <span :class="{active:menu_listones==1}" @click="switching(1)">首页</span>
            <span :class="{active:menu_listones==2}" @click="switching(2)">产品中心</span>
            <el-dropdown placement='bottom' @command="handleCommand"  :show-timeout='showtimeout'>
              <b :class="{active:menu_listones==3}">解决方案</b>
              <!-- <b :class="{active:menu_listones==3}" @click="switching(3)">解决方案</b> -->
              <el-dropdown-menu slot="dropdown" v-for="(item,key) in solutList" :key="key">
                <el-dropdown-item v-for="(item,key) in solutList" :key="key" :command="item">{{item.newsTitle}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <!-- <span   :class="{active:menu_listones==3}" @click="switching(3)">解决方案</span> -->
            <span :class="{active:menu_listones==12}" @click="switching(12)">应用案例</span>
            <span :class="{active:menu_listones==4}" @click="switching(4)">新闻资讯</span>
<!--            <span :class="{active:menu_listones==5}" @click="switching(5)">关于我们</span>-->
            <el-dropdown placement='bottom' @command="handleCommandAbout"  :show-timeout='showtimeout'>
              <b :class="{active:menu_listones==5}">关于我们</b>
              <!-- <b :class="{active:menu_listones==3}" @click="switching(3)">解决方案</b> -->
              <el-dropdown-menu slot="dropdown" v-for="(item,key) in aboutUsList" :key="key">
                <el-dropdown-item v-for="(item,key) in aboutUsList" :key="key" :command="item">{{item}}
                </el-dropdown-item>


              </el-dropdown-menu>
            </el-dropdown>
            <span :class="{active:menu_listones==6}" @click="switching(6)">招商加盟</span>
          </div>
        </el-col>
        <el-col :span="5">
          <ul>
            <li index="1" v-if="ifLogin" @click="loginHandler">
              <div style="width: 100%;" class="texe_change">
                <span>{{loginChanges}}</span>
              </div>
              <div :class="{navSelect:selectedIndex === 1}" class="selected_marker_container_left">
                <div class="selected_marker selected_marker_right"></div>
              </div>
              <div class="selected_marker_container_right">
                <div class="selected_marker selected_marker_left"></div>
              </div>
            </li>
            <li index="1" v-if="!ifLogin" @click="goBackstage">
              <div style="width: 100%;" class="texe_change">
                <span>进入吻胜云后台</span>
              </div>
              <div :class="{navSelect:selectedIndex === 1}" class="selected_marker_container_left">
                <div class="selected_marker selected_marker_right"></div>
              </div>
              <div class="selected_marker_container_right">
                <div class="selected_marker selected_marker_left"></div>
              </div>
            </li>
          </ul>

        </el-col>
      </el-row>
    </div>

    <div class="content">
      <!-- <el-scrollbar style="height: 100%"> -->
      <router-view> </router-view>
      <el-carousel v-if="menu_listones == 1" :height="bannerHeight + 'px'" class="new_lunbo">
        <el-carousel-item v-for="item in imageBox" :key="item.id">
          <img :src="item.adImgUrl" alt="">
          <!-- <img :src="item.url" alt="" :width="bannerWidth + 'px'" :height="bannerHeight + 'px'"> -->
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="block" v-if="menu_listones == 1">
        <el-carousel class="lunbotu">
          <el-carousel-item v-for="item in imageBox" :key="item.id">
            <img :src="item.url" class="image" />
          </el-carousel-item>
        </el-carousel>
        </div> -->

      <div class="titleDisplay" v-if="menu_listones== 1">
        <div class="item-title">
          <p>产品中心</p>
          <span> </span>
          <p style="margin-top:3rem"></p>
        </div>
        <div class="middle_show">
          <!-- <div  style="margin:0 auto;"> -->
          <div v-if='productHomelist' class="middle_showchild"  @click="examineWsh(productHomelist)" >
            <div>
              <img :src="productHomelist.newsImgUrl"  alt='logo' />
            </div>

             <b> {{productHomelist.newsTitle}}</b>
          <p>{{ productHomelist.newsSubtitle }}</p>

          </div>
          <div v-if='productHomelist2' class="middle_showchild"  @click="examineWsh(productHomelist2)">
           <div>
              <img :src="productHomelist2.newsImgUrl"  alt='logo' />
            </div>

             <b> {{productHomelist2.newsTitle}}</b>
          <p>{{ productHomelist2.newsSubtitle }}</p>
          </div>
          <div v-if='productHomelist3'  class="middle_showchild"  @click="examineWsh(productHomelist3)">
             <div>
              <img :src="productHomelist3.newsImgUrl"  alt='logo' />
            </div>

             <b> {{productHomelist3.newsTitle}}</b>
          <p>{{ productHomelist3.newsSubtitle }}</p>
          </div>
          <!-- </div> -->
        </div>

        <!-- 第二层 -->
        <div class="middle_show">

          <div v-if='productHomelist4'  class="middle_showchild2" @click="examineWsh(productHomelist4)">
            <div>
              <img :src="productHomelist4.newsImgUrl"  alt='logo' />
            </div>

             <b> {{productHomelist4.newsTitle}}</b>
          <p>{{ productHomelist4.newsSubtitle }}</p>

          </div>
          <div v-if='productHomelist5'  class="middle_showchild"  @click="examineWsh(productHomelist5)">
            <div>
              <img :src="productHomelist5.newsImgUrl"  />
              <!-- <img src="../../assets/productCut/yjsd.jpg" /> -->
            </div>
         <b> {{productHomelist5.newsTitle}}</b>
          <p>{{ productHomelist5.newsSubtitle }}</p>
          </div>
        </div>
        <div class="middle_show3">
          <P class="check_more" @click="checkMore()">查看更多>></P>
        </div>
        <div class="major_service">
          <div class="item-title">
            <p>新闻资讯</p>
            <span>
            </span>
            <p style="margin-top:3rem"></p>
          </div>
          <div class="middle_major_show">
            <div v-if="tableList" class="middle_major_showchild" @click="houseHold(tableList)"
              @mouseenter='handleMouseenter' @mouseleave='handleMouseleave'>
              <img :src="tableList.newsImgUrl" />
              <transition name="slide-fade">
                <div class="showchild_title">
                  <!-- 关键时刻能救命，一文讲清..... -->
                  {{ tableList.newsTitle|ellipsis }} <br>
                </div>
              </transition>
              <transition name="slide-fade">
                <div class="galleryOverlay" v-if="show">
                  <br>

                  <span @click="houseHold(tableList)">
                    查看详情>
                  </span>

                </div>
              </transition>
            </div>
            <div v-if="tableList2" class="middle_major_showchild" @click="houseHold(tableList2)"
              @mouseenter='handleMouseenter2' @mouseleave='handleMouseleave2'>
              <img :src="tableList2.newsImgUrl" />
              <transition name="slide-fade">
                <div class="showchild_title">
                  {{ tableList2.newsTitle|ellipsis }} <br>
                </div>
              </transition>
              <transition name="slide-fade">
                <div class="galleryOverlay" v-if="show2">
                  <br>

                  <span @click="houseHold(tableList2)">
                    查看详情>
                  </span>
                </div>
              </transition>
            </div>
          </div>
          <div class="middle_major_show">
            <div v-if="tableList3" class="middle_major_showchild" @click="houseHold(tableList3)"
              @mouseenter='handleMouseenter3' @mouseleave='handleMouseleave3'> <img :src="tableList3.newsImgUrl" />
              <transition name="slide-fade">
                <div class="showchild_title">
                  {{ tableList3.newsTitle|ellipsis }} <br>
                </div>
              </transition>
              <transition name="slide-fade">
                <div class="galleryOverlay" v-if="show3">
                  <br>

                  <span @click="houseHold(tableList3)">
                    查看详情>
                  </span>
                </div>
              </transition>

            </div>
            <div v-if="tableList4" class="middle_major_showchild" @click="houseHold(tableList4)"
              @mouseenter='handleMouseenter4' @mouseleave='handleMouseleave4'><img :src="tableList4.newsImgUrl" />
              <transition name="slide-fade">
                <div class="showchild_title">
                  {{ tableList4.newsTitle|ellipsis }} <br>
                </div>
              </transition>
              <transition name="slide-fade">
                <div class="galleryOverlay" v-if="show4">
                  <br>

                  <span @click="houseHold(tableList4)">
                    查看详情>
                  </span>
                </div>
              </transition>
            </div>
          </div>
          <div class="middle_show3">
            <P class="check_more" @click="checkMoreNew()">查看更多>></P>
          </div>
        </div>

        <div style="clear: both;"></div>

      </div>


      <FooterNav  @childEvent="openReportingCenter" />
      <!-- 回到顶部，鼠标滑过固定格式 -->
      <div style="position: fixed;top:60%;right:1rem; z-index: 100;cursor: pointer;display:flex;"  >
        <div>
            <img src="../../assets/merchantJoin/zhaoshang.png" alt="" v-show="zhaoshang"  class="clearfix">
            <img src="../../assets/merchantJoin/shouhou.png" alt="" v-show="shouhou"  class="clearfix">
            <img src="../../assets/merchantJoin/wensheng.png" alt="" v-show="wensheng"  class="clearfix">
          </div>



<div>
<div style="width:2rem;background-color: #fff;display:flex;flex-direction:column;padding:8px;box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);
border-radius: 10px;">
  <img  @mouseenter="onMouseOverzs"  @mouseleave="onMouseOutzs"  src="../../assets/merchantJoin/jiameng.png" alt="">
      <img src="../../assets/merchantJoin/fiexdline.png" alt="" style="margin:10px; ">
       <img src="../../assets/merchantJoin/kefu.png"  @mouseenter="onMouseOversh"  @mouseleave="onMouseOutsh" alt="">
         <img src="../../assets/merchantJoin/fiexdline.png" alt=""  style="margin:10px;">
           <img src="../../assets/merchantJoin/weixin.png"  @mouseenter="onMouseOverws"  @mouseleave="onMouseOutws" alt="">

</div>
<div   @click="goTop" style="margin-top:0.5rem;width:2rem;background-color: #fff;display:flex;flex-direction:column;padding:8px;box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
border-radius: 10px;">
   <img   src="../../assets/merchantJoin/goback.png" alt="">
</div>
</div>


    </div>
     <!-- <el-backtop target=".content" :bottom="goback_bottom">
        <div class="gobacklist" >
       <img src="../../assets/merchantJoin/goback.png" alt="">
        </div>
      </el-backtop> -->
    </div>
    <!-- </div> -->
    <el-dialog title="违法和不良信息举报" :visible.sync="addDialogFormVisible" :close-on-click-modal="false">
      <el-form :model="addForm" size="small"  style="50%" :rules="addRules" ref="addRules"
               enctype="multipart/form-data">
        <el-form-item label="邮箱地址" :label-width="formLabelWidth" prop="email">
          <el-input v-model="addForm.email" autocomplete="off" style="width:415px" >
          </el-input>
        </el-form-item>
        <el-form-item label="举报标题" :label-width="formLabelWidth" prop="title">
          <el-input v-model="addForm.title" autocomplete="off" style="width:415px;">
          </el-input>
        </el-form-item>
<!--        <el-form-item label="活动类型" :label-width="formLabelWidth" prop="activityType">-->
<!--          <el-select v-model="addForm.activityType " placeholder="请选活动类型">-->
<!--            <el-option label="抽奖" value="0"></el-option>-->
<!--            <el-option label="兑换" value="1"></el-option>-->
<!--            <el-option label="领取" value="2"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="举报内容" :label-width="formLabelWidth" prop="desc">
          <el-input v-model="addForm.desc" autocomplete="off" style="width:415px" type="textarea" :rows="7">
          </el-input>
        </el-form-item>



      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitReporting('addForm')" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>
<script>
  import HeadNav from "../../components/HeadNav";
  import FooterNav from "../../components/FooterNav";
  import Storage from "../../utils/Storage";
  import productCenter from "./productCenter";

  export default {
    name: "First",
    components: {
      HeadNav,
      FooterNav,
      productCenter
    },
    data() {
      return {
        goback_bottom:10,
        zhaoshang:false,
        wensheng:false,
        shouhou:false,
        addDialogFormVisible:false,
        addForm:{
          email:"",
          title:"",
          desc:""
        },

        addRules: {
          title: [{
            required: true,
            message: "请输入举报标题",
            trigger: "blur"
          }],
          email: [{
            required: true,
            message: "请输入邮箱",
            trigger: "blur"
          }
          ],
          desc: [{
            required: true,
            message: "请输入举报内容",
            trigger: "blur"
          },],
        },
        imageBox: [
          // {
          //   id: 0,
          //   url: require("../../assets/banner4.jpg")
          // },
          // {
          //   id: 1,
          //   url: require("../../assets/banner2.jpg")
          // },
          // {
          //   id: 2,
          //   url: require("../../assets/banner1.jpg")
          // },
          // {
          //   id: 3,
          //   url: require("../../assets/banner5.jpg")
          // }

        ],
        select: "",
        // 图片父容器高度
        bannerHeight: 0,
        bannerWidth: 0,
        // 浏览器宽度
        screenWidth: 0,
        showBac: "",
        ifLogin: "",
        isTop: true,
        tianmaopng: require("../../assets/login/tm.svg"),
        jingdongpng: require("../../assets/login/jd.svg"),
        loginChanges: "吻胜云登录",
        input: "",
        menu_listones: 1,
        shouYe: true,
        selectedIndex: 4,
        current: 1,
        pageSize: 4,
        show: false,
        show2: false,
        show3: false,
        show4: false,
        tableList: [], //新闻资讯列表
        tableList2: [], //新闻资讯列表
        tableList3: [], //新闻资讯列表
        tableList4: [], //新闻资讯列表
        solutList: [],
        aboutUsList: ["企业简介","企业文化","企业荣誉","下载中心"],
        productHomelist:[],
        productHomelist2:[],
        productHomelist3:[],
        productHomelist4:[],
        productHomelist5:[],
        showtimeout:0,
        active:'',
      };
    },
    filters: {
      ellipsis(value) {
        if (!value) return "";
        if (value.length > 12) {
          return value.slice(0, 12) + "...";
        }
        return value;
      }
    },
    watch: {
      $route(to, from) {
        console.log(to.path);
        if (to.path == "/") {
          this.menu_listones = 1;
          document.querySelector(".content").scrollTop = 0;

          // firefox浏览器中是这句
          document.querySelector(".content").scrollTop = 0;
          console.log("首页");
        } else if (to.path == "/aboutUs" || to.path == "/downloadCenter") {
          document.body.scrollTop = 0;
          this.menu_listones = 5;
          document.querySelector(".content").scrollTop = 0;

          document.querySelector(".content").scrollTop = 0;
        } else if (to.path == "/solution") {
          document.querySelector(".content").scrollTop = 0;

          // firefox浏览器中是这句
          document.querySelector(".content").scrollTop = 0;
          this.menu_listones = 3;
        } else if (to.path == "/newsInformation" || to.path == "/household") {
          this.menu_listones = 4;
          document.querySelector(".content").scrollTop = 0;

          document.querySelector(".content").scrollTop = 0;
        } else if (
          to.path == "/productCenter" ||
          to.path == "/fireAlarm" ||
          to.path == "/fireAlarmNn" ||
          to.path == "/methane" ||
          to.path == "/methaneNn" ||
          to.path == "/carbonMonoxide" ||
          to.path == "/carbonMonoxideNn" ||
          to.path == "/smoke" ||
          to.path == "/smokeLl" ||
          to.path == "/smokeWw" ||
          to.path == "/flashlight" ||
          to.path == "/fireBlanket" ||
          to.path == "/jylh" ||
          to.path == "/dynamicPage" ||
          to.path == "/fireExtinguisher"
        ) {
          this.menu_listones = 2;
          document.querySelector(".content").scrollTop = 0;

          // firefox浏览器中是这句
          document.querySelector(".content").scrollTop = 0;
        } else if (to.path == "/merchantsToJoin") {
          document.body.scrollTop = 0;
          this.menu_listones = 6;
          document.querySelector(".content").scrollTop = 0;

          // document.querySelector(".content").scrollTop = 0
        }
      }
    },
    mounted() {
      this.bannerWidth = window.innerWidth;
      this.bannerHeight = window.innerWidth * 0.337;
      console.log(this.bannerHeight, this.bannerWidth);
      document.body.scrollTop = 0;
      // firefox浏览器中是这句
      document.documentElement.scrollTop = 0;
      // 首次加载时,需要调用一次
      this.screenWidth = window.innerWidth;
      this.setSize();
      // 窗口大小发生改变时,调用一次
      window.onresize = () => {
        this.screenWidth = window.innerWidth;
        this.setSize();
      };
      console.log(this.$route.path);
      if (this.$route.path == "/productCenter") {
        this.menu_listones = 2;
      } else if (this.$route.path == "/solution") {
        this.menu_listones = 3;
      } else if (this.$route.path == "/newsInformation") {
        this.menu_listones = 4;
      } else if (this.$route.path == "/aboutUs" || this.$route.path == "/downloadCenter") {
        this.menu_listones = 5;

      } else if (this.$route.path == "/applicationcases") {
        this.menu_listones = 12;
      } else if (this.$route.path == "/") {
        this.menu_listones = 1;
      } else if (this.$route.path == "/merchantsToJoin") {
        this.menu_listones = 6;
      } else if (this.$route.path == "/household") {
        this.menu_listones = 4;
      } else if (
        this.$route.path == "/jylh" ||
        this.$route.path == "/fireExtinguisher" ||
        this.$route.path == "/fireBlanket" ||
        this.$route.path == "/flashlight" ||
        this.$route.path == "/smokeWw" ||
        this.$route.path == "/smokeLl" ||
        this.$route.path == "/smoke" ||
        this.$route.path == "/carbonMonoxideNn" ||
        this.$route.path == "/carbonMonoxide" ||
        this.$route.path == "/methaneNn" ||
        this.$route.path == "/methane" ||
        this.$route.path == "/fireAlarmNn" ||
        this.$route.path == "/dynamicPage" ||
        this.$route.path == "/productCenter" ||
        this.$route.path == "/fireAlarm"
      ) {
        this.menu_listones = 2;
      }

      this.newsList();
      this.solutionList();
      this.productHome();//首页产品展示
      this.getUserAccess();
      this.advertisementList();
    },
    created() {
      if (Storage.getItem("token")) {
        let Base64 = require("js-base64").Base64;
        let token = Storage.getItem("token");
        let array = token.split(".");
        let userinfo = Base64.decode(array[1]);
        let time = JSON.parse(userinfo).exp * 1000;
        // chrome

        if (Date.now() < time) {
          this.showBac = true;
          this.ifLogin = false;
        } else {
          this.showBac = false;
          this.ifLogin = true;
        }
      } else {
        this.showBac = false;
        this.ifLogin = true;
      }
    },

    methods: {
      submitReporting(){
        this.$api
            .submitReporting({
              "content": this.addForm.desc,
              "email": this.addForm.email,
              "title": this.addForm.title

            })
            .then(res => {
              this.$message.success("已收到您的举报信息，会尽快为您进行核实处理");
              this.addDialogFormVisible = false
            });
      },
      openReportingCenter(){
        this.addDialogFormVisible = true;
      },
      //请求列表数据函数
    advertisementList() {
         this.$api
        .advertisementList({
          // web轮播改成4 ,
          queryDto: {
            mark: '4',
          }
        })
        .then(res => {
          console.log(res.data.data)
            this.imageBox = res.data.data.records
        });
      },
      // 招商
      onMouseOverzs(){
        this.zhaoshang = true
      },
      onMouseOutzs(){
         this.zhaoshang = false
      },
       // 售后
      onMouseOversh(){
        this.shouhou = true
      },
      onMouseOutsh(){
         this.shouhou = false
      },
       // 吻胜
      onMouseOverws(){
        this.wensheng = true
      },
      onMouseOutws(){
         this.wensheng = false
      },
      // 回到顶部
       goTop() {
         console.log('回到顶部')
      document.querySelector(".content").scrollTop = 0;

    // }, 10)
  },
      setSize() {
        // 通过浏览器宽度(图片宽度)计算高度
        // this.bannerHeight = 850 / 1920 * this.screenWidth;
        // this.bannerHeight = this.screenWidth*0.44
      },
      //    为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
      // scrollToTop() {
      //   const that = this
      //   let scrollTop = window.pageYOffset ||  document.querySelector(".new_lunbo ").scrollTop || document.body.scrollTop
      //   that.scrollTop = scrollTop
      //   console.log( that.scrollTop)
      //   that.btnFlag = false
      // },
      handleMouseenter() {
        this.show = true;
      },
      handleMouseleave() {
        this.show = false;
      },
      handleMouseenter2() {
        this.show2 = true;
      },
      handleMouseleave2() {
        this.show2 = false;
      },
      handleMouseenter3() {
        this.show3 = true;
      },
      handleMouseleave3() {
        this.show3 = false;
      },
      handleMouseenter4() {
        this.show4 = true;
      },
      handleMouseleave4() {
        this.show4 = false;
      },
      //   详情跳转到灭火器文
      houseHold(item) {

        console.log(item.id);
        if(item.clickUrl == null || item.clickUrl == ""){
          this.menu_listones = 4;
          this.$router.replace({
            path: `/household`,
            query: {
              crumbsTitle: "首页",
              data: item.id,
              current:1,
              size: 4,
            }
          });
        }else {
          window.open(
              item.clickUrl,
              "_blank"
          );

        }

      },
      //   详情跳转到吻胜出席移动
      mobileCloud() {
        this.menu_listones = 4;
        this.$router.replace({
          path: `/mobileCloud`,
          query: {
            crumbsTitle: "首页"
          }
        });
      },
      // 详情跳转搞事情消防产品
      fireProducts() {
        this.menu_listones = 4;
        this.$router.replace({
          path: `/fireProducts`,
          query: {
            crumbsTitle: "首页"
          }
        });
      },
      // 喜报
      prosperity() {
        this.menu_listones = 4;
        this.$router.replace({
          path: `/prosperity`,
          query: {
            crumbsTitle: "首页"
          }
        });
      },
      // handleScroll() {
      //   console.log( document.querySelector(".first").scrollTop)

      //   var scrollTop = document.querySelector(".first").scrollTop
      //   if (scrollTop <= 0) {
      //     console.log("页面滚动到顶部");
      //     this.isTop = true;
      //   } else {
      //     console.log("页面离开顶部");
      //     this.isTop = false;
      //   }
      //   console.log("scrollTop:" + scrollTop);
      // },
      //登录事件
      loginHandler() {
        this.$router.push("/login");
      },

      goBackstage() {
        this.$router.replace("/mainRouter");
      },
      goUrl(url) {
        window.open(url, "_blank");
      },
      //  houseHold(data) {
      //         this.$router.push({
      //             path: `/household`,
      //             query: {
      //                 data: data.newsContentUrl
      //             }

      //         });
      //     },
      // 查看更多跳转产品页面
      checkMore() {
        this.menu_listones = 2;
        this.$router.push({
          path: "/productCenter"
        });
      },
      //查看更多跳转新闻资讯
      checkMoreNew() {
        this.menu_listones = 4;
        this.$router.push({
          path: "/newsInformation"
        });
      },
      // 一氧化碳
      carbonMonoxide() {
        this.menu_listones = 2;
        this.$router.replace({
          path: `/carbonMonoxide`,
          query: {
            crumbsTitle: "首页"
          }
        });
      },
      // 查看独立式感烟探测报警器6101
      examineWsh(data) {
        this.menu_listones = 2;
        console.log(data)
        this.$router.push({
          path: `/dynamicPage`,
          query: {
            data: data.id,
           crumbsTitle: "首页"
          }

        });
      },
      //家用可燃气体探测器6200
      methane() {
        this.menu_listones = 2;
        this.$router.replace({
          path: `/methane`,
          query: {
            crumbsTitle: "首页"
          }
        });
      },
      // 手电筒
      flashlight() {
        this.menu_listones = 2;
        this.$router.replace({
          path: `/flashlight`,
          query: {
            crumbsTitle: "首页"
          }
        });
      },

      // 灭火器
      fireExtinguisher() {
        this.menu_listones = 2;

        this.$router.replace({
          path: `/fireExtinguisher`,
          query: {
            crumbsTitle: "首页"
          }
        });
      },
      switching(item) {
        this.menu_listones = item;
        console.log(item);
        if (item == 1) {
          this.$router.push({
            path: "/"
          });
        } else if (item == 2) {
          this.$router.push({
            path: "/productCenter"
          });
        } else if (item == 5) {
          this.$router.push({
            path: "/aboutUs/#target",

          });
        } else if (item == 3) {
          // this.$router.push({
          //   path: "/solution"
          // });
        } else if (item == 4) {
          this.$router.push({
            path: "/newsInformation"
          });
          this.isTop = true;
        } else if (item == 6) {
          this.$router.push({
            path: "/merchantsToJoin"
          });
        }else if (item == 12) {
          this.$router.push({
            path: "/applicationcases"
          });
        }
      },
       //新闻资讯列表
      productHome() {
        this.$api
        .sysNewsProductListhome({
            page: {
            current:'1',
            size: '5'
            }
        })
        .then(res => {
          console.log(res.data.data.records)
          this.productHomelist = res.data.data.records[0];
          this.productHomelist2 = res.data.data.records[1];
          this.productHomelist3 = res.data.data.records[2];
          this.productHomelist4 = res.data.data.records[3];
          this.productHomelist5 = res.data.data.records[4];

        })
        .catch(error => {
          console.log(error);
        });
      },
      //新闻资讯列表
      newsList() {
        this.$api
          .newsList({
            page: {
              current: this.current,
              size: this.pageSize
            }
          })
          .then(res => {
            if (res.data.code == "200") {
              console.log(res.data.data.records);
              this.tableList = res.data.data.records[0];
              this.tableList2 = res.data.data.records[1];
              this.tableList3 = res.data.data.records[2];
              this.tableList4 = res.data.data.records[3];
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      getUserAccess() {
        this.$api
          .userAccess()
          .then(res => {})
          .catch(error => {
            console.log(error);
          });
      },
      //解决方案列表
      solutionList() {
        this.$api
          .solutionList()
          .then(res => {
            console.log(res.data.data);
            this.solutList = res.data.data;
          })
          .catch(error => {
            console.log(error);
          });
      },
      handleCommand(command) {
        console.log(command);
        this.$router.push({
          path: "/solution",
          query: {
            id: command.id
          }
        });
        console.log(command.id);
      },
       handleCommandAbout(command) {
      if(command =='企业文化'){
        this.$router.push({
          path: "/aboutUs",
          query:{
            id:'target'
          }
        });
      }else if(command =='企业荣誉'){
        this.$router.push({
          path: "/aboutUs",
          query:{
            id:'honor'
          }
        });
      }else if(command =='下载中心'){
        this.$router.push({
          path: "/downloadCenter",
        });
      }else{
        this.$router.push({
          path: "/aboutUs",

        });

      }

      },
      mouseOver() {
        this.tianmaopng = require("../../assets/login/tm-h.svg");
      },
      mouseLeave() {
        this.tianmaopng = require("../../assets/login/tm.svg");
      },
      mouseOverJd() {
        this.jingdongpng = require("../../assets/login/jd-h.svg");
      },
      mouseLeaveJd() {
        this.jingdongpng = require("../../assets/login/jd.svg");
      }
    },
    destroyed() {
      //  window.removeEventListener('scroll', this.scrollToTop)
    }
  };
</script>
<style lang="less" scoped>
  .el-scrollba {
    // &.is-vertical{
    width: 50px; //滚动条宽度
    // }
  }

  .el-dropdown-menu__item:hover {
    color: #666;
    background-color: rgba(240, 207, 135, 0.301); //  border: #000 2px solid;
  }

  @media screen and (min-width: 0px) and (max-width: 1200px) {
    .first {
      background-color: #fafafa;
      overflow-x: auto;
      // overflow-y: scroll;
      min-width: 1000px;
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: 3000px;
      margin: 0 auto;

      ::-webkit-scrollbar {
        width: 5px;
        height: 1px;
      }

      ::-webkit-scrollbar-thumb {
        //滑块部分
        // border-radius: 5px;
        background-color: #1da3f1;
      }

      ::-webkit-scrollbar-track {
        //轨道部分
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #ededed;
        border-radius: 5px;
      }
    }
  }

  @media screen and (min-width: 1201px) {
    .first {
      background-color: #fafafa;
      // border: red 3px solid;
      overflow-x: auto;
      min-width: 1300px;

      display: flex;
      flex-direction: column;
      height: 100%;
      max-width:3000px;
      margin: 0 auto;

      ::-webkit-scrollbar {
        width: 5px;
        height: 1px;
      }

      ::-webkit-scrollbar-thumb {
        //滑块部分
        // border-radius: 5px;
        background-color: #1da3f1;
      }

      ::-webkit-scrollbar-track {
        //轨道部分
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #ededed;
        border-radius: 5px;
      }
    }
  }

  // .wrapper {
  //   display: flex;
  //   flex-direction: column;
  //   height: 100%;
  //   max-width: 1920px;
  //   margin: 0 auto;
  // }

  .new_lunbo {
    margin: 0 auto;
    width: 100%;
    z-index: 1;
    /deep/.el-carousel__container {
      // margin: 0 auto;
      width: 100%;
      text-align: center;

      img {
        width: 100%;
        margin: 0 auto;
      }
    }

    /deep/.el-carousel__button {
      border: 3px solid #f28c02;
      border-radius: 5px 5px;
      padding: 5px 10px;
    }
  }

  // @media screen and (min-width: 0px) and (max-width: 1831px) {
  //   .block {
  //     width: 100%;
  //     // border: 1px solid red;
  //     // margin-top: 5rem;

  //     .lunbotu {
  //       /deep/.el-carousel__container {
  //         width: 91rem;
  //         height: 36rem;
  //         margin: 0 auto;
  //       }

  //       // height: 100%;
  //       // width: 100%;
  //       .image {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }

  //     /deep/.el-carousel__button {
  //       border: 3px solid #f28c02;
  //       border-radius: 5px 5px;
  //       padding: 5px 10px;
  //     }
  //   }
  // }

  // @media screen and (min-width: 1371px) and (max-width:1832px) {
  //   .block {
  //     width: 100%;
  //     // border: 1px solid red;
  //     // margin-top: 5rem;

  //     .lunbotu {
  //       /deep/.el-carousel__container {
  //         width: 100%;
  //         height: 41rem;
  //          margin: 0  auto;
  //       }

  //       // height: 100%;
  //       // width: 100%;
  //       .image {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }

  //     /deep/.el-carousel__button {
  //       border: 3px solid #f28c02;
  //       border-radius: 5px 5px;
  //       padding: 5px 10px;
  //     }
  //   }
  // }

  // @media screen and (min-width: 1832px) {
  //   .block {
  //     width: 100%;

  //     // margin-top: 5rem;
  //     // height: 45rem;
  //     .lunbotu {
  //       /deep/.el-carousel__container {
  //         width: 120rem;
  //         height: 53.125rem;
  //         margin: 0 auto;
  //       }

  //       // height: 100%;
  //       // width: 100%;

  //       .image {
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }

  //     /deep/.el-carousel__button {
  //       border: 3px solid #f28c02;
  //       border-radius: 5px 5px;
  //       padding: 5px 10px;
  //     }

  //     // .el-carousel__container {
  //     //   position: relative;
  //     //   height: 450 / 16rem;
  //     // }
  //   }
  // }

  .selected_marker_container_left {
    width: 50%;
    float: left;
    height: 20px;
    background: #12b7f500;
    border: none;
    overflow: hidden;
  }

  .selected_marker_container_right {
    width: 50%;
    float: right;
    height: 20px;
    background: #12b7f500;
    border: none;
    overflow: hidden;
  }

  .index_nav {
    position: fixed;
    width: 100%;
    height: 41px;
    margin-right: 2rem;

    overflow: hidden;
    //z-index: 10000;
    z-index: 1000011;
    background: #fafafa;
    border-bottom: 1px solid #d9d9d9;

    //transition: all 0.5s;

    ul {
      width: 100%;
      padding-right: 0.2rem;
      height: 30px;
      float: right;

      text-align: center;
      border-bottom: #dddddd;

      li {
        font-size: 0.875rem;
        display: inline-block;
        height: 28px;
        vertical-align: middle;
        text-align: center;
        float: right;

        span {
          display: inline-block;
          padding: 8px 2px 0 2px;
        }

        list-style: none;
        cursor: pointer;

        //transition: font-size 0.5s, font-weight 0.5s;
        //navSelect
        .selected_marker {
          //background: #ff994f;
          width: 0;
          background: #12b7f5;
          //text-align: center;
          margin-top: 17px;
          margin-left: 0;
          margin-right: 0;
          height: 3px;
          border: none;
          transition: width 0.2s;
          overflow: hidden;
        }

        .selected_marker_left {
          float: left;
        }

        .selected_marker_right {
          float: right;
        }

        &:hover {
          //background: #128bf533;
          //font-size: 1rem;
          //font-weight: bolder;
          //border-bottom: 2px solid #12b7f5;

          .selected_marker {
            //vertical-align: bottom;
            //text-align: center;
            width: 100%;
          }
        }
      }
    }
  }

  .index_nav_top {
    // background: #333 !important;
    // border-bottom: 1px solid #d9d9d900;

    // ul {
    //   background: #333 !important;

    //   li {
    //     background: #333 !important;
    //     color: #b2b1af;
    //   }
    // }
  }

  // 菜单导航样式
  //  .menuNav{
  //      display:fixed;
  //   //  height: 5rem;
  //   overflow:scroll;
  //  }
  .content {
    flex: 1;
    overflow-y: scroll;
  }

  .menuNavigation {
    // margin-top: 41px;
    // width: 100%;
    z-index: 2000;
    // position: fixed;
    // top: 0;

    // overflow-x: auto;
    // overflow-y: hidden;
    // right: 0;
    box-shadow: 0 0 2px 0 rgb(197, 196, 196);
    // bottom: 0;
    // left: 0;
    // margin-left: 10px;
    height: 5rem;

    background-color: #fff;

    .el-button {
      padding: 1px 1px 1px 10px;
    }

    .imgLogo {
      // height: 5rem;
      //  margin: 0 auto;
      // line-height:5rem ;

      // padding-left: 22%;
      .logon_tit {
        @media screen and (min-width: 0px) and (max-width: 1370px) {
          img {
            height: 40px;
            margin-top: 1.2rem;
          }
        }

        @media screen and (min-width: 1371px) {
          img {
            height: 50px;
            margin-top: 0.8rem;
          }
        }

        //        img {
        //   height: 50px;
        // }
      }

      .shop_logo {
        // margin-left:1rem;
        // margin:auto;
        transform: translateY(50%);
        display: inline-block;

        @media screen and (min-width: 0px) and (max-width: 1370px) {
          img {
            width: 1.46rem;
            margin-top: 0.7rem;
          }
        }

        @media screen and (min-width: 1371px) {
          img {
            margin-top: 0.5rem;
          }
        }

        img {
          margin-left: 1rem;
        }

        img:hover {
          animation: changDeg 1s linear 0.1s infinite;
          cursor: pointer;
        }

        @keyframes changDeg {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    /deep/.el-input-group__append,
    .el-input-group__prepend {
      background-color: #fff !important;
    }

    @media screen and (min-width: 0px) and (max-width: 1370px) {
      .menu_list {
        font-size: 0.94rem;

        .el-dropdown {
          font-size: 0.94rem;

          b {
            padding: 2rem 0;
          }
        }

        .el-dropdown:hover {
          border-bottom: 3px solid #f7950f;

          box-sizing: border-box;
          height: 4.9rem;
          margin-top: 1px;
          line-height: 4.9rem;
        }

        span:hover {
          border-bottom: 3px solid #f7950f;
          box-sizing: border-box;
          height: 4.9rem;
          margin-top: 1px;
          line-height: 4.9rem;
        }
      }
    }

    @media screen and (min-width: 1371px) {
      .menu_list {
        font-size: 1.125rem;

        .el-dropdown {
          font-size: 1.125rem;

          b {
            // border: 1px pink solid;
            padding: 2rem 0;
          }

          /deep/.el-dropdown-menu__item:hover {
            //  color: red;
          }
        }

        .el-dropdown:hover {
          border-bottom: 3px solid #f7950f;
          box-sizing: border-box;
          //  border: 1px red solid;
          height: 4.8rem;
          margin-top: 3px;
          line-height: 4.8rem;
        }

        span:hover {
          border-bottom: 3px solid #f7950f;
          box-sizing: border-box;
          height: 4.8rem;
          margin-top: 3px;
          line-height: 4.8rem;
        }
      }
    }

    .menu_list {
      height: 5rem;
      // font-size: 1.125rem;
      font-weight: 600;
      color: #666666;
      display: flex;
      cursor: pointer;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;

      .active {
        color: #f7950f;
        box-sizing: border-box;
        // transition: all .8s;
        // border-bottom:  1px solid #f7950f;
      }

      // span:hover {
      //   border-bottom: 3px solid #f7950f;
      //   box-sizing: border-box;
      //   height: 4.8rem;
      //   margin-top: 3px;
      //   line-height: 4.8rem;
      // }
    }

    ul {
      width: 100%;
      padding-left: 3rem;
      text-align: center;
      border-bottom: #dddddd;
      box-sizing: border-box;

      @media screen and (min-width: 0px) and (max-width: 1370px) {
        .texe_change {
          font-size: 0.94rem;
          height: 5rem;
          line-height: 5rem;
        }
      }

      @media screen and (min-width: 1371px) {
        .texe_change {
          font-size: 1.125rem;
          height: 5rem;
          line-height: 5rem;
        }
      }

      div {
        padding: 0;
        margin: 0;
      }

      li {
        color: #a1a1a1;
        display: inline-block;
        // height: 80px;
        // line-height: 80px;
        margin: 0 auto;

        vertical-align: middle;
        text-align: center;

        span {
          display: inline-block;
          // padding: 8px 2px 0 2px;
        }

        list-style: none;
        cursor: pointer;

        //transition: font-size 0.5s, font-weight 0.5s;
        //navSelect
        .selected_marker {
          width: 0;
          box-sizing: border-box;
          background: #a1a1a1;
          //text-align: center;
          // margin-top: 17px;
          margin-left: 0;

          margin-right: 0;
          height: 3px;
          border: none;
          transition: width 0.2s;
          overflow: hidden;
        }

        .selected_marker_left {
          float: left;
        }

        .selected_marker_right {
          float: right;
        }

        //.navSelect{
        //  font-weight: bold;
        //}
        //margin-bottom: 1px;
        &:hover {
          //background: #128bf533;
          //font-size: 1rem;
          //font-weight: bolder;
          //border-bottom: 2px solid #12b7f5;

          .selected_marker {
            //vertical-align: bottom;
            //text-align: center;
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (min-width: 0px) and (max-width: 1370px) {
    .middle_show {
      // width: 64%;
      margin: 0 auto;
      // height: 24rem;
      margin-bottom: 1.4rem;
      display: flex;
      justify-content: center;

      // border: #12b7f5 1px solid;
      .middle_showchild {
        margin-right: 0.7rem;
        margin-left: 0.7rem;
        width: 18rem;
        text-align: center;
        height: 100%;
        border-radius: 10px;
        cursor: pointer;
        border: 1px solid #fff;
        background-color: #fff;
      }

      .middle_showchild2 {
        width: 37.4rem;
        border-radius: 10px;
        text-align: center;
        height: 100%;
        margin-right: 0.7rem;
        margin-left: 0.7rem;
        cursor: pointer;
        border: 1px solid #fff;
        background-color: #fff;
      }
    }
  }

  @media screen and (min-width: 1371px) {
    .middle_show {
      width: 64%;
      margin: 0 auto;
      // height: 31.875rem;
      margin-bottom: 2rem;
      //  border: #12b7f5 1px solid;
      justify-content: center;
      display: flex;

      .middle_showchild {
        width: 23.75rem;
        text-align: center;
        height: 100%;
        margin-right: 0.90625rem;
        margin-left: 0.90625rem;
        border-radius: 10px;
        cursor: pointer;
        border: 1px solid #fff;
        background-color: #fff;
      }

      .middle_showchild2 {
        width: 49.3125rem;
        border-radius: 10px;
        text-align: center;
        height: 100%;
        margin-right: 0.90625rem;
        margin-left: 0.90625rem;
        cursor: pointer;
        border: 1px solid #fff;
        background-color: #fff;
      }
    }
  }

  .middle_show3 {
    width: 64%;
    margin: 0 auto;
    // height: 24rem;
    margin-top: 2.5rem;

    display: flex;

    @media screen and (min-width: 1371px) {
      .check_more {
        font-size: 1.5rem;
      }
    }

    @media screen and (min-width: 0px) and (max-width: 1370px) {
      .check_more {
        font-size: 1.2rem;
      }
    }

    .check_more {
      width: 100%;

      color: #333333;
      text-align: right;
      cursor: pointer;
      margin-right: 0.90625rem;
      margin-bottom: 5.5rem;
    }

    .check_more:hover {
      color: #f7950f;
    }
  }

  .middle_show {
    .middle_showchild:hover {
      box-shadow: 5px 5px 2px rgba(241, 169, 72, 0.18);

      img {
        transform: scale(1.2);
      }
    }

    .middle_showchild2:hover {
      box-shadow: 5px 5px 2px rgba(241, 169, 72, 0.18);

      img {
        transform: scale(1.2);
      }
    }

    .middle_showchild {

      // width: 23.75rem;
      .imgBgsdt {
        width: 18%;
        //  height: 100%;
        // height: 20rem;
        // border: 1px solid red;
        margin: 0 auto;
        margin-top: 25%;
        margin-bottom: 2%;
      }

      img {
        width: 70%;
        // height: 20rem;
        // border: 1px solid red;
        margin: 0 auto;
        margin-top: 25%;
        margin-bottom: 10%;
      }

      img:hover {
        transform: scale(1.2);
      }

      @media screen and (min-width: 1371px) {
        b {
          font-size: 1.25rem;
          text-align: center;
          color: #000;
          line-height: 3rem;
          display: block;
        }

        p {
          font-size: 1.25rem;
          padding: 0 0 3rem 0;
          text-align: center;
          line-height: 1.7rem;
        }
      }

      @media screen and (min-width: 0px) and (max-width: 1370px) {
        b {
          font-size: 1.13rem;
          text-align: center;
          color: #000;
          line-height: 2.6rem;
          // padding-right: 10px;
          display: block;
        }

        p {
          font-size: 1.13rem;
          padding: 0 0 1.8rem 0;
          line-height: 1.4rem;
          // padding: 0 0 50px 0;
          // padding-right: 10px;
          text-align: center;
        }
      }
    }

    .middle_showchild2 {
      // width: 49.3125rem;
      // border-radius: 10px;
      // text-align: center;
      // height: 100%;
      // margin-right: 0.90625rem;
      // margin-left: 0.90625rem;
      // cursor: pointer;
      // border: 1px solid #fff;
      // background-color: #fff;

      img {
        width: 46%;
        // border: 1px solid red;
        margin: 0 auto;
        margin-top: 13%;
        margin-bottom: 0.5%;
        //   margin-bottom: 10%;
      }

      img:hover {
        transform: scale(1.2);
      }

      @media screen and (min-width: 1371px) {
        b {
          font-size: 1.25rem;
          text-align: center;
          // margin-top: 3rem;
          line-height: 3rem;
          color: #000;
          padding-right: 10px;
          display: block;
        }

        p {
          font-size: 1.25rem;
          padding: 0 0 3rem 0;
          padding-right: 10px;
          text-align: center;

        }
      }

      @media screen and (min-width: 0px) and (max-width: 1370px) {
        b {
          font-size: 1.13rem;
          text-align: center;
          color: #000;
          // margin-top: 3rem;
          line-height: 2.547rem;
          // padding-right: 10px;
          display: block;
        }

        p {
          font-size: 1.13rem;
          padding: 0 0 1.8rem 0;
          // padding-right: 10px;
          text-align: center;
        }
      }
    }
  }

  .major_service {
    width: 100%;
    background: #ffffff;
    padding-bottom: 20px;
    margin-top: 20px;

    .middle_major_show {
      width: 64%;
      // height: 14rem;
      // border: 1px solid red;
      margin: 0 auto;
      // margin-top: 2rem;
      display: flex;
      justify-content: center;
      padding-bottom: 1.4rem;

      .middle_major_showchild {
        width: 48%;
        position: relative;
        background-color: #fff;
        height: 100%;
        margin: 0 0.75rem;
        overflow: hidden;
        cursor: pointer;

        .showchild_title {
          position: absolute;
          width: 100%;
          height: 3rem;
          // line-height: 3rem;
          color: white;
          padding-left: 20px;
          padding-top: 1rem;
          // margin-bottom: 1px;
          transition: all 0.5s;
          padding-bottom: 6px;
          background: linear-gradient(rgba(17, 15, 15, 0), rgba(51, 51, 51, 1));
          // opacity: 0.7;
          bottom: 1px;
          font-size: 1.4rem;
          overflow: hidden;
        }

        .galleryOverlay {
          position: absolute;
          width: 100%;
          height: 4rem;
          // line-height: 3rem;
          color: white;
          padding-left: 20px;
          transition: all 0.5s;
          // background: linear-gradient(rgba(51, 51, 51, 0), rgba(51, 51, 51, 1));
          // opacity: 0.7;
          bottom: 3px;
          font-size: 1.4rem;
          overflow: hidden;

          span {
            font-size: 1rem;

            color: #f7950f;
            // color: white;
            cursor: pointer;
          }
        }

        .slide-fade-enter,
        .slide-fade-leave-to {
          transform: translateY(4rem);
          opacity: 0;
        }

        .slide-fade-enter-active {
          transition: all 0.5s;
          // span{
          //      color: #f7950f;
          // }
        }

        .slide-fade-leave-active {
          transition: all 0.5s;
          //  span{
          //      color:white;
          // }
        }

        p {
          margin-top: -3rem;
          // display: none;
          width: 100%;
          height: 3rem;
          line-height: 3rem;
          opacity: 0;
          position: absolute;
          bottom: -20px;
          font-size: 1.4rem;
          transition: all 1.5s;
          // animation-fill-mode: forwards;
          //  transition: all 0.3s;
        }

        img {
          width: 100%;
          z-index: 2;
        }
      }

      .middle_major_showchild:hover {
        .showchild_title {
          height: 4rem;
          transition: all 0.5s;
        }
      }
    }
  }

  .nav_container {
    float: right;
    margin-right: 2rem;
    background: red;

    li {
      margin-left: 2rem;
      display: inline-block;
      list-style: none;
    }
  }

  .nav_container_top {
    background: #12b7f500 !important;

    ul {
      background: #12b7f500 !important;

      li {
        background: #12b7f500 !important;
      }
    }
  }

  .product_content {
    width: 100%;
    height: 50rem;

    //position: relative;
    .imageBox {
      width: 100%;
      // height: 35rem;
      background-image: url("../../assets/rotationChart/chanpin.jpg");
      background-size: cover;
      position: relative;
      transition: width 0.3s, height 1s;
      background-position: center center;
      //overflow: auto;
    }

    .introduce {
      height: 15rem;
      text-align: center;
      vertical-align: middle;
      color: #645d58;
      transition: width 0.3s, height 1s;
      position: relative;

      .product_title_top_layer {
        height: 3rem;
        padding-top: 6rem;
        transition: padding-top 0.3s;
        //padding-bottom: 6rem;
      }
    }

    .background-layer {
      //background: #12b7f5;
      width: 0;
      height: 15rem;
      position: absolute;
      z-index: 10000;
      transition: width 0.3s, height 1s;
      background-image: url("../../assets/rotationChart/index_bg2.jpg");
      background-size: cover;

      &:hover {
        width: 100%;
        background: #12b7f5;
      }
    }

    &:hover {
      .introduce {
        height: 20rem;
        color: #fafafa;

        //margin-top: 2rem;
        .product_title_top_layer {
          //height: 3rem;
          padding-top: 8rem;
          //padding-bottom: 6rem;
        }

        .background-layer {
          //background: #12b7f5;
          background-image: url("../../assets/rotationChart/index_bg2.jpg");
          background-size: cover;
          width: 100%;
          height: 20rem;
          position: absolute;
          z-index: 10000;
        }
      }

      .imageBox {
        // height: 30rem;
      }
    }
  }

  .titleDisplay {
    background-color: #efefef;
    width: 100%;

    // padding-bottom: 7rem;
    @media screen and (min-width: 0px) and (max-width: 1370px) {
      .item-title {
        font-size: 1.86rem;
      }
    }

    @media screen and (min-width: 1371px) {
      .item-title {
        font-size: 1.125rem;
      }
    }

    .item-title {
      padding-top: 6rem;
      text-align: center;
      color: #333333;
      width: 100%;
      letter-spacing: 5px;
      margin: 0 auto;
      font-size: 2.1rem;
      font-weight: 550;

      p {
        text-shadow: 1px 1px 1px #666;
        //  text-decoration: underline;
        // border-bottom: 8px solid #f28c02;
        display: inline-block;
        padding: 0 50px;
      }

      span {
        width: 14%;
        margin: 0 auto;
        height: 8px;
        margin-top: -10px;
        display: block;
        // background: #f28c02;
        background: rgba(242, 140, 2, 0.7);
      }
    }
  }

  .introduceOne,
  .introduceTwo {
    position: relative;
    z-index: 100000;
  }

  .introduceTwo {
    margin-top: 0.5rem;
  }

  //
  //.nav_selected {
  //  //border-bottom: 2px solid #ff994f;
  //  //overflow: hidden;
  //  color: #128bf5;
  //}

  .link-content {
    margin: 0 5% 0 5%;
    width: 90%;
    height: 10rem;

    //background: #ff994f;
    .link_item {
      margin-top: 2rem;
      text-align: center;
      width: 100%;
      transition: margin-top 0.5s;

      .link-item-marker {
        margin-top: 0.5rem;
        width: 100%;
        height: 3px;
        background: #0c9cf000;
        transition: background-color 0.5s;
      }

      &:hover {
        margin-top: 1rem;

        .link-item-marker {
          background: #0c9cf0ee;
        }
      }
    }
  }
/deep/.el-backtop{
  background-color: rgba(0, 0, 0,0);
}
  .gobacklist {
    height: 100%;
    width: 100%;


    img{
       height: 100%;
    width: 100%;
    }
    text-align: center;
    line-height: 40px;

  }
    .clearfix{
          display: inline-block;
          width: 8rem;
              }

</style>

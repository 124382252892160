import axios from "../utils/http"
import base from "./base"

const api = {
    //登录函数
    getLogin(params) {
        return axios.post(base.baseUrl + base.loginUrl, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    },
    //菜单请求函数
    getMenuList() {
        return axios.get(base.baseUrl + base.menuListUrl);
    },
    //模块列表请求函数
    permissionList() {
        return axios.get(base.baseUrl + base.permissionListUrl)
    },
    //新增模块请求函数
    addModuleHandler(params) {
        // console.log(params)
        return axios.post(base.baseUrl + base.addModuleUrl, params)
    },
    //删除模块请求函数
    deleteModuleHandler(id) {
        return axios.get(base.baseUrl + base.deleteModuleUrl, {
            params: {
                "id": id
            }
        })
    },
    //编辑模块请求函数
    editModuleHandler(params) {
        return axios.post(base.baseUrl + base.editModuleUrl, params)

    },
    //新增权限请求函数
    plusPower(params) {
        return axios.post(base.baseUrl + base.plusPowerUrl, params)
    },
    //更新权限请求函数
    updatePower(params) {
        return axios.post(base.baseUrl + base.updatePowerUrl, params)
    },
    //删除权限函数
    deletePower(params) {
        return axios.post(base.baseUrl + base.deletePowerurl, params)
    },
    //角色列表函数
    getRoleList(params) {
        return axios.post(base.baseUrl + base.getRoleListUrl, params)
    },
    //编辑角色函数
    editRole(params) {
        return axios.post(base.baseUrl + base.editRoleUrl, params)
    },
    //删除角色函数
    deleteRole(params) {
        return axios.post(base.baseUrl + base.deleteRoleUrl, params)
    },
    //添加角色函数
    addRole(params) {
        return axios.post(base.baseUrl + base.addRoleUrl, params)
    },
    //角色详情函数
    roleDetail(id) {
        return axios.get(base.baseUrl + base.roleDetailUrl, {
            params: {
                'id': id
            }
        })
    },
    //用户详情函数-当前登录
    userDetail() {
        return axios.get(base.baseUrl + base.userDetailUrl)
    },
    //微信绑定
    wxBind(params) {
        return axios.post(base.baseUrl + base.wxBind, params)
    },
    //微信解绑
    wxUnBind() {
        return axios.post(base.baseUrl + base.wxUnBind)
    },
    //微信登录
    wxLogin(params) {
        return axios.post(base.baseUrl + base.wxLogin, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

    },
    //获取支付链接
    getPayUrl(params) {
        return axios.post(base.baseUrl + base.getPaylUrl, params)
    },
    //用户列表函数
    userTable(params) {
        return axios.post(base.baseUrl + base.userTableUrl, params)
    },
    //设备列表函数
    deviceList(params) {
        return axios.post(base.baseUrl + base.deviceListUrl, params)
    },
    //电气火灾设备列表函数
    sunshyList(params) {
        return axios.post(base.baseUrl + base.sunshyList, params)
    },
    //注册列表
    deviceRegisterList(params) {
        return axios.post(base.baseUrl + base.registerListUrl, params)
    },
    //查询白名单
    getWhiteList(params) {
        return axios.get(base.baseUrl + base.getWhiteList, {
            params: {
                'accessNumber': params
            }
        })
    },
    //注册设备
    registDeivce(params) {
        return axios.post(base.baseUrl + base.registerDeviceUrl, params)
    },


    //产品列表函数
    productList(params) {
        return axios.post(base.baseUrl + base.productListUrl, params)
    },
    //新增产品
    addProduct(params) {
        return axios.post(base.baseUrl + base.productAdd, params)
    },
    //删除产品
    delProduct(params) {
        return axios.post(base.baseUrl + base.productDel, params)
    },
    //产品列表函数
    productListForPullUrl(params) {
        return axios.post(base.baseUrl + base.productListForPullUrl, params)
    },
    //新增设备函数
    addDevice(params) {
        return axios.post(base.baseUrl + base.addDeviceUrl, params)
    },
    //新增产品品类类函数
    addProductCategory(params) {
        return axios.post(base.baseUrl + base.addProductCategoryUrl, params)
    },
    //产品品类列表函数
    productCategoryList(params) {
        return axios.post(base.baseUrl + base.productCategoryListUrl, params)
    },
    //产品品类-第三方列表
    productListThird(params) {
        return axios.post(base.baseUrl + base.productListThird, params)
    },
    //产品品类下拉列表接口
    categoryListForPull(params) {
        return axios.post(base.baseUrl + base.categoryListForPullUrl, params)
    },
    //协议列表函数
    protocolList() {
        return axios.get(base.baseUrl + base.protocolListUrl)
    },



    //删除产品类函数
    deleteProductCategory(params) {
        return axios.post(base.baseUrl + base.delProductCategoryUrl, params)
    },
    //删除设备函数
    deleteDevice(params) {
        return axios.post(base.baseUrl + base.delDeviceUrl, params)
    },
    //删除设备函数
    deleteIotDevice(params) {
        return axios.post(base.baseUrl + base.iotDelDeviceUrl, params)
    },
    //查询注册结果
    getRegisterResult(params) {
        return axios.post(base.baseUrl + base.getRegisterResult, params)
    },
    //查询删除结果
    getDeleteResult(params) {
        return axios.post(base.baseUrl + base.getDeleteResult, params)
    },

    getMapDevices(params) {
        return axios.post(base.baseUrl + base.getMapDevices, params)
    },
    //用户详情函数-id获取
    userDetailId(id) {
        return axios.get(base.baseUrl + base.detailUserUrl, {
            params: {
                'id': id
            }
        })
    },
    //用户详情函数-id获取
    getVoiceCardInfo(iccid) {
        return axios.get(base.baseUrl + base.getVoiceCardInfo, {
            params: {
                'iccid': iccid
            }
        })
    },//用户详情函数-id获取
    getVoiceCardList(imei) {
        return axios.get(base.baseUrl + base.getVoiceCardList, {
            params: {
                'imei': imei
            }
        })
    },

    //卡信息同步
    voiceCardInfoUpdate() {
        return axios.get(base.baseUrl + base.voiceCardInfoUpdate, )
    },
    //角色下拉框函数
    roleListSelect() {
        return axios.get(base.baseUrl + base.roleListSelectUrl)
    },
    //编辑角色函数
    editUser(params) {
        return axios.post(base.baseUrl + base.editUserUrl, params)
    },
    //用户子账号列表函数
    userChild(id) {
        return axios.get(base.baseUrl + base.userChildUrl, {
            params: {
                id: id
            }
        })
    },
    //用户增加函数
    addUserHandler(params) {
        return axios.post(base.baseUrl + base.addUserUrl, params)
    },
    //三级联动地图函数
    areaSanjiHandler(code) {
        return axios.get(base.baseUrl + base.areaUrl, {
            params: {
                code: code
            }
        })
    },
    //删除用户函数
    deleteUser(id) {
        return axios.get(base.baseUrl + base.deleteUserUrl, {
            params: {
                id: id
            }
        })
    },
    //子账号分配函数
    childDispatchHandler(params) {
        return axios.post(base.baseUrl + base.childIdsDispatchUrl, params)
    },
    //子账号下拉函数
    dispatchList() {
        return axios.get(base.baseUrl + base.dispatchUrl)
    },
    //编辑模块请求函数
    getDeviceInfoForMac(mac) {
        return axios.get(base.baseUrl + base.getDeviceInfoForMac, {
            params: {
                mac: mac
            }
        })
    },
    //根据产品ID获取产品详情函数
    getProductDetial(id) {
        return axios.get(base.baseUrl + base.getProductDetailUrl, {
            params: {
                productId: id
            }
        })
    },
    //产品数据点列表请求函数
    getDataPointList(params) {
        return axios.post(base.baseUrl + base.getDataPointLisrUrl, params, )
    },
    //编辑产品请求函数
    updateProduct(params) {
        return axios.post(base.baseUrl + base.updateProductUrl, params)
    },
    //新增数据点函数
    addDatePoint(params) {
        return axios.post(base.baseUrl + base.addDatePointUrl, params)
    },
    //删除数据点函数
    deleteDataPoint(id) {
        return axios.get(base.baseUrl + base.deleteDataPointUrl, {
            params: {
                dataPointId: id
            }
        })
    },
    //编辑数据点函数
    editDataPoint(params) {
        return axios.post(base.baseUrl + base.editDataPointUrl, params)
    },

    getDeviceStatusNum() {
        return axios.get(base.baseUrl + base.getDeviceStatusNum)
    },
    getDeviceFaultStatusNum() {
        return axios.get(base.baseUrl + base.getDeviceFaultStatusNum)
    },
    getLastWeekStatisticsInfo() {
        return axios.get(base.baseUrl + base.getLastWeekStatisticsInfo)
    },
    getLastWeekDeviceFaultStatisticsInfo() {
        return axios.get(base.baseUrl + base.getLastWeekDeviceFaultStatisticsInfo)
    },

    //最新上报数据函数
    recentlyReportedData(mac) {
        return axios.get(base.baseUrl + base.recentlyReportedDataUrl, {
            params: {
                deviceMac: mac
            }
        })
    },
    //获取摄像头列表
    getCameraList(roomId) {
        return axios.post(base.baseUrl + base.getCameraListUrl,  {
                roomId: roomId
            }
        )
    },
    //获取摄像头列表
    getCameraStream(sn,type) {
        return axios.post(base.baseUrl + base.getCameraStream,  {
                sn: sn,
                previewStreamType: type
            }
        )
    },//获取摄像头列表
    getChildrenDevice(net) {
        return axios.get(base.baseUrl + base.getChildrenDevice, {
            params: {
                net: net
            }
        })
    },

    //设备详情请求函数
    deviceDetail(id) {
        return axios.get(base.baseUrl + base.deviceDetailUrl, {
            params: {
                deviceId: id
            }
        })
    },
    //设备实时状态函数
    deviceStatus(id) {
        return axios.get(base.baseUrl + base.deviceStatusUrl, {
            params: {
                deviceId: id
            }
        })
    },
    //场地列表请求函数
    placeList(params) {
        return axios.post(base.baseUrl + base.placeListUrl, params)
    },
    //删除场地函数
    deletePlace(params) {
        return axios.post(base.baseUrl + base.deletePlaceUrl, params)
    },
    //获取火警列表
    getFireList(params) {
        return axios.post(base.baseUrl + base.getFireList, params)
    },
    //获取故障列表
    getFaultList(params) {
        return axios.post(base.baseUrl + base.getFaultList, params)
    },
    //获取火警详情
    getFireDetail(params) {
        return axios.get(base.baseUrl + base.getFireInfo, params)
    },
    //获取故障详情
    getFaultDetail(params) {
        return axios.get(base.baseUrl + base.getFaultInfo, params)
    },

    //场地详情请求函数
    placeDetail(placeId) {
        return axios.get(base.baseUrl + base.placeDetailUrl, {
            params: {
                id: placeId
            }
        })
    },
    //房间列表请求函数
    roomList(params) {
        return axios.post(base.baseUrl + base.roomListUrl, params)
    },
    //用户列表请求函数
    userList(params) {
        return axios.post(base.baseUrl + base.userListUrl, params)
    },
    //分配设备下拉用户列表函数
    childProjectlist(params) {
        return axios.post(base.baseUrl + base.childProjectlistUrl, params)
    },
    //批量分配设备函数
    deviceDispatch(params) {
        return axios.post(base.baseUrl + base.deviceDispatchUrl, params)
    },
    //批量解绑设备函数
    deviceUnbinding(params) {
        return axios.post(base.baseUrl + base.deviceUnbindingUrl, params)
    },

    //批量清除设备信息
    deviceCleanInfo(params) {
        return axios.post(base.baseUrl + base.deviceCleanInfoUrl, params)
    },
    //批量导入SN码
    importSN(params) {
        return axios.post(base.baseUrl + base.importSN, params)
    },
    //导出文件
    exportFile(params) {
        return axios.post(base.baseUrl + base.exportFile, params, {
            responseType: "arraybuffer"
        })
    },
    //历史数据数据点下拉框请求函数
    dataPotinSelect(mac) {
        return axios.get(base.baseUrl + base.dataPointSelectUrl, {
            params: {
                mac: mac
            }
        })
    },
    //历史数据图表接口
    dataPointEcharts(params) {
        return axios.post(base.baseUrl + base.dataPointEchartsUrl, params)
    },
    //历史数据表格请求函数
    dataPointTable(params) {
        return axios.post(base.baseUrl + base.dataPointTableUrl, params)
    },
    //设备故障类型列表
    deviceFaultType(params) {
        return axios.post(base.baseUrl + base.deviceFaultTypeUrl, params)
    },
    //新增故障类型函数
    addDeviceFault(params) {
        return axios.post(base.baseUrl + base.addDeviceFaultUrl, params)
    },
    //删除故障类型函数
    deleteDeviceFault(id) {
        return axios.get(base.baseUrl + base.deleteDeviceFaultUrl, {
            params: {
                id: id
            }
        })
    },
    //设备维修记录请求函数
    deviceRepair(params) {
        return axios.post(base.baseUrl + base.deviceRepairUrl, params)
    },
    //新增设备维修记录
    addDeviceRepari(params) {
        return axios.post(base.baseUrl + base.addDeviceRepariUrl, params)
    },
    //故障类型下拉列表函数
    deviceRepairList() {
        return axios.get(base.baseUrl + base.deviceRepairListUrl)
    },
    //删除设备维修记录函数
    deleteDeviceRepair(id) {
        return axios.get(base.baseUrl + base.deleteDeviceRepairUrl, {
            params: {
                id: id
            }
        })
    },
    //协议列表请求函数
    agreementList(params) {
        return axios.post(base.baseUrl + base.agreementListUrl, params)
    },
    //新增协议请求函数
    addAgreement(params) {
        return axios.post(base.baseUrl + base.addAgreementUrl, params)
    },
    //删除协议请求函数
    deleteAgreement(id) {
        return axios.get(base.baseUrl + base.deleteAgreementUrl, {
            params: {
                protocolId: id
            }
        })
    },
    //协议详情请求函数
    agreementDetail(id) {
        return axios.get(base.baseUrl + base.agreementDetailUrl, {
            params: {
                protocolId: id
            }
        })
    },
    //协议详情请求函数
    getCode(mobile) {
        return axios.get(base.baseUrl + base.getCode, {
            params: {
                mobile: mobile
            }
        })
    },
    editAgreement(params) {
        return axios.post(base.baseUrl + base.editAgreementUrl, params)
    },
    forgetPwd(params) {
        return axios.post(base.baseUrl + base.forgetPwd, params)
    },
    logout() {
        return axios.get(base.baseUrl + base.logout)
    },
    //历史数据全部请求函数
    allDeviceListStatus(params) {
        return axios.post(base.baseUrl + base.allDeviceListStatus, params)
    },
    //webLog表格请求函数
    getLogListUrl(params) {
        return axios.post(base.baseUrl + base.logListUrl, params)
    },
    //appLog表格请求函数
    getAppLogListUrl(params) {
        return axios.post(base.baseUrl + base.appLogListUrl, params)
    },
    //上次登录IP信息接口
    getLastLogInfo() {
        return axios.get(base.baseUrl + base.lastLogInfoUrl)
    },
    //添加广告函数
    addAdvertisementHandler(params) {
        return axios.post(base.baseUrl + base.addAdvertisementUrl, params, {
                headers: {
                    "content-Type": 'multipart/form-data',
                },
            }

        )
    },
    //广告列表展示函数
    advertisementList(params) {
        return axios.post(base.baseUrl + base.advertisementListUrl, params)
    },

    submitReporting(params) {
        return axios.post(base.baseUrl + base.submitReportingUrl, params)
    },
    //广告列表删除函数
    advertisementDeleteHandler(deleteId) {
        return axios.get(base.baseUrl + base.advertisementDeleteUlr, {
            params: {
                id: deleteId
            }
        })
    },
    //广告列表修改函数
    advertisementEditHandler(params) {
        return axios.post(base.baseUrl + base.advertisementEditUrl, params, {
            headers: {
                "content-Type": 'multipart/form-data',
            },
        })
    },
    //添加楼层函数
    addFloorHandler(params) {
        return axios.post(base.baseUrl + base.addFloorUrl, params)
    },
    //楼层板块列表函数
    floorPlateHandler(params) {
        return axios.post(base.baseUrl + base.floorPlateListUrl, params)
    },
    //新增版块接口
    addPlateHandler(params) {
        return axios.post(base.baseUrl + base.addPlateUrl, params, {
            headers: {
                "content-Type": 'multipart/form-data',
            },
        })
    },
    //版块列表函数
    plateListHandler(params) {
        return axios.post(base.baseUrl + base.plateListUrl, params)
    },
    //删除楼层函数
    deleteFloorHandler(id) {
        return axios.get(base.baseUrl + base.deleteFloorUrl, {
            params: {
                id: id
            }
        })
    },
    editFloorHandler(params) {
        return axios.post(base.baseUrl + base.editFloorUrl, params)
    },
    //删除版块函数
    deletePlateHandler(id) {
        return axios.get(base.baseUrl + base.deletePlateUrl, {
            params: {
                id: id
            }
        })
    },
    //编辑版块函数
    editPlateHandler(params) {
        return axios.post(base.baseUrl + base.editPlateUrl, params, {
            headers: {
                "content-Type": 'multipart/form-data',
            },
        })
    },
    //快捷查询接口函数
    quickQueryHandler(params) {
        return axios.post(base.baseUrl + base.quickQueryUrl, params)
    },
    //测试结果列表函数
    testResultHandler(params) {
        return axios.post(base.baseUrl + base.testResultUrl, params)
    },
    //测试通过率函数
    testFateHandler(params) {
        return axios.post(base.baseUrl + base.testFateUrl, params)
    },
    //跳转二维码函数
    FirstQrcodeHandler(params) {
        return axios.post(base.baseUrl + base.firstQrcodeUrl, params)
    },
    testResultDetail(params) {
        return axios.post(base.baseUrl + base.testResultDetailUrl, params)
    },
    //是否合格请求函数
    ifPassHandler(params) {
        return axios.post(base.baseUrl + base.ifPassUrl, params)
    },
    //刷新测试结果列表接口
    refrashTestResultHandler(params) {
        return axios.post(base.baseUrl + base.refrashTestResultUrl, params)
    },
    //测试是否注册Mac函数
    checkMacHandler(mac) {
        return axios.get(base.baseUrl + base.checkMacUrl, {
            params: {
                mac: mac
            }
        })
    },
    //测试(久远)是否注册Mac函数
    checkJyMacHandler(mac) {
        return axios.get(base.baseUrl + base.checkJyMacUrl, {
            params: {
                mac: mac
            }
        })
    },
    //测试结果导出函数
    testResultExportHandler(params) {
        return axios.post(base.baseUrl + base.testResultExportUrl, params, {
            responseType: "arraybuffer"
        })

    },
    //查询设备测试结果
    // queryTestResult(params) {
    //     return axios.get(base.baseUrl + base.qeuryTestResult, params)
    // },
    queryTestResult(macs) {
        return axios.get(base.baseUrl + base.qeuryTestResult, {
            params: {
                macs: macs
            }
        })
    },
    //

    //app列表请求函数
    appListHandler(params) {
        return axios.post(base.baseUrl + base.appListUrl, params)
    },
    //新增app版本函数
    addAppHandler(params) {
        return axios.post(base.baseUrl + base.uploadAddUrl, params, {
            headers: {
                "content-Type": 'multipart/form-data',
            },
        })
    },
    //删除app版本函数
    deleteAppHandler(id) {
        return axios.get(base.baseUrl + base.deleteAppUrl, {
            params: {
                id: id
            }
        })
    },
    //编辑app版本函数
    editAppHandler(params) {
        return axios.post(base.baseUrl + base.editAppUrl, params, {
            headers: {
                "content-Type": 'multipart/form-data',
            },
        })
    },
    //刷新数据点缓存函数
    refrashHandler() {
        return axios.get(base.baseUrl + base.refrashUrl)
    },
    //账号列表展示接口
    accountList(params) {
        return axios.post(base.baseUrl + base.accountListUrl, params)
    },
    reportingList(params) {
        return axios.post(base.baseUrl + base.reportingList, params)
    },
    //账号类型列表展示接口
    accountType(params) {
        return axios.get(base.baseUrl + base.accountTypeUrl)
    },
    //删除账号类型接口
    deleteAccountType(id) {
        return axios.get(base.baseUrl + base.deleteAccountType, {
            params: {
                "id": id
            }
        })
    },
    //账号新增列表展示接口
    accountTypeAdd(params) {
        return axios.post(base.baseUrl + base.accountTypeUrlAdd, params)
    },
    //账号类型修改接口
    accountTypeUpdate(params) {
        return axios.post(base.baseUrl + base.accountTypeUrlUpdate, params)
    },
    //账号列表新增平台账号下拉框接口
    accountListSelector() {
        return axios.get(base.baseUrl + base.accountListSelector)
    },
    //账号新增列表展示接口
    accountListAdd(params) {
        return axios.post(base.baseUrl + base.accountListAddUrl, params)
    },

    //账号修改列表展示接口
    accountListUpdate(params) {
        return axios.post(base.baseUrl + base.accountListUpdateUrl, params)
    },
    //删除账号列表接口
    accountTypeDeleteU(id) {
        return axios.get(base.baseUrl + base.accountTypeDeleteUrl, {
            params: {
                "id": id
            }
        })
    },
    //节点列表展示接口
    nodeList(params) {
        return axios.post(base.baseUrl + base.nodeListUrl, params)
    },
    //问题状态列表展示接口
    questionStatus(params) {
        return axios.post(base.baseUrl + base.questionStatusUrl, params)
    },
    //问题状态列新增接口
    questionStatusAdd(problemStatus) {
        return axios.get(base.baseUrl + base.questionStatusAddUrl, {
            params: {
                "problemStatus": problemStatus
            }
        })
    },
    //删除问题状态接口
    questionStatusDelete(id) {
        return axios.get(base.baseUrl + base.questionStatusDeleteUrl, {
            params: {
                "id": id
            }
        })
    },
    //节点新增列表展示接口
    nodeListAdd(params) {
        return axios.post(base.baseUrl + base.nodeListAddUrl, params)
    },
    //节点修改列表展示接口
    nodeListUpdate(params) {
        return axios.post(base.baseUrl + base.nodeListUpdateUrl, params)
    },
    //删除节点接口
    nodeListDelete(id) {
        return axios.get(base.baseUrl + base.nodeListDeleteUrl, {
            params: {
                "id": id
            }
        })
    },
    //模板列表展示接口
    moduleList(params) {
        return axios.post(base.baseUrl + base.moduleListUrl, params)
    },
    //节点新增列表展示接口
    moduleListAdd(params) {
        return axios.post(base.baseUrl + base.moduleListAddUrl, params)
    },
    //模板修改列表展示接口
    moduleListUpdate(params) {
        return axios.post(base.baseUrl + base.moduleListUpdateUrl, params)
    },
    //删除模板接口
    moduleListDelete(id) {
        return axios.get(base.baseUrl + base.moduleListDeleteUrl, {
            params: {
                "id": id
            }
        })
    },
    //修改模板配置
    configEdit(params) {
        return axios.post(base.baseUrl + base.configEdit, params)
    },
    //模板详情展示接口
    moduleDetails(id) {
        return axios.get(base.baseUrl + base.moduleDetailsUrl, {
            params: {
                "id": id
            }
        })
    },
    //默认处理者下拉框接口
    moduleselectorList(params) {
        return axios.get(base.baseUrl + base.moduleselectorListUrl, params)
    },
    //节点名称下拉框下拉框接口
    nodeselectorListUrl(params) {
        return axios.get(base.baseUrl + base.nodeselectorListUrl, params)
    },
    //回滚下拉框接口
    nodepreTemplateConfig(problemId) {
        return axios.get(base.baseUrl + base.nodepreTemplateConfigUrl, {
            params: {
                "problemId": problemId
            }
        })
    },
    //修改新建列表展示接口
    nodepreconfigAdd(params) {
        return axios.post(base.baseUrl + base.nodepreconfigAddUrl, params)
    },
    //删除模板接口
    nodeConfigDelete(params) {
        return axios.post(base.baseUrl + base.nodeConfigDeleteUrl, params)
    },
    //问题列表展示接口
    problemList(params) {
        return axios.post(base.baseUrl + base.problemListUrl, params)
    },

    //问题列表指派接口
    problemReassignment(params) {
        return axios.post(base.baseUrl + base.problemReassignmentUrl, params)
    },
    //问题列表处理接口
    problemHandle(params) {
        return axios.post(base.baseUrl + base.problemHandleUrl, params)
    },
    //问题详情接口
    problemDetail(id) {
        return axios.get(base.baseUrl + base.problemDetailUrl, {
            params: {
                "id": id
            }
        })
    },
    //问题详情接口
    changeProblemLevel(params) {
        return axios.post(base.baseUrl + base.severity, params)
    },
    //问题流程接口
    problemHandleProcess(id) {
        return axios.get(base.baseUrl + base.problemHandleProcessUrl, {
            params: {
                "id": id
            }
        })
    },

    //问题模板下拉框接口
    processTemplateselector(params) {
        return axios.get(base.baseUrl + base.processTemplateselectorUrl, params)
    },
    //问题新增接口
    problemAdd(params) {
        return axios.post(base.baseUrl + base.problemAddUrl, params)
    },
    //问题导出接口
    problemExport(params) {
        return axios.post(base.baseUrl + base.problemExportUrl, params, {
            responseType: "arraybuffer"
        })
    },
    //问题类型列表接口
    problemTypeList(params) {
        return axios.post(base.baseUrl + base.problemTypeListUrl, params)
    },
    //问题类型删除
    problemTypeDelete(id) {
        return axios.get(base.baseUrl + base.problemTypeDeleteUrl, {
            params: {
                "id": id
            }
        })
    },
    //问题新增接口
    problemTypeAdd(params) {
        return axios.post(base.baseUrl + base.problemTypeAddUrl, params)
    },
    //已处理火警消息列表接口
    deviceDoneFireList(params) {
        return axios.post(base.baseUrl + base.deviceDoneFireListUrl, params)
    },
    //待处理火警消息列表接口
    deviceFireList(params) {
        return axios.post(base.baseUrl + base.deviceFireListUrl, params)
    },
    //设备火警处理
    deviceFireDeal(params) {
        return axios.post(base.baseUrl + base.deviceFireDealUrl, params)
    },
    //当前账号故障消息列表
    devicefaultList(params) {
        return axios.post(base.baseUrl + base.devicefaultListUrl, params)
    },
    //当前账号故障消息列表
    getFireAndFaultNum(params) {
        return axios.post(base.baseUrl + base.fireAndDeviceCount, params)
    },
    //故障已处理消息列表
    doneFauttList(params) {
        return axios.post(base.baseUrl + base.doneFauttListlUrl, params)
    },
    //故障设备处理
    faultDeall(params) {
        return axios.post(base.baseUrl + base.faultDeallUrl, params)
    },
    //火警高发时间分布月度统计
    fireTimeMonthStatistics(params) {
        return axios.post(base.baseUrl + base.fireTimeMonthStatisticsUrl, params)
    },

    //火警高发时间分布周统计
    fireTimeWeekStatistics(params) {
        return axios.post(base.baseUrl + base.fireTimeWeekStatisticsUrl, params)
    },
    //  火警统计列表
    fireStatistics(params) {
        return axios.post(base.baseUrl + base.fireStatisticsUrl, params)
    },
    //  火警统计列表导出
    exportDeviceFire(params) {
        return axios.post(base.baseUrl + base.exportDeviceFireUrl, params, {
            responseType: "arraybuffer"
        })

    },
    //  火警周期统计列表
    fireCycleStatisticsUrl(params) {
        return axios.post(base.baseUrl + base.fireCycleStatisticsUrl, params)
    },
    // 火警周期统计列表导出
    exportDeviceCycle(params) {
        return axios.post(base.baseUrl + base.exportDeviceCycleUrl, params, {
            responseType: "arraybuffer"
        })

    },
    //  获取默认处理人
    problemDefaultHandler(params) {
        return axios.post(base.baseUrl + base.problemDefaultHandlerUrl, params)
    },
    //  火警高发场地top统计
    firePlaceTopStatistics(params) {
        return axios.post(base.baseUrl + base.firePlaceTopStatisticsUrl, params)
    },
    //  火警高发项目top统计
    fireProjectTopStatistics(params) {
        return axios.post(base.baseUrl + base.fireProjectTopStatisticsUrl, params)
    },
    //  新闻资讯列表展示
    newsList(params) {
        return axios.post(base.baseUrl + base.newsList, params)
    },
    //  案例列表展示
    appCasesList(params) {
        return axios.post(base.baseUrl + base.appCasesList, params)
    },
    //  案例首页列表展示
    appCasesFirstList(params) {
        return axios.post(base.baseUrl + base.appCasesFirstList, params)
    },
    //  新增资讯资讯
    addNews(params) {
        return axios.post(base.baseUrl + base.addNews, params)
    },
    //  新增资讯资讯
    addApplicationCases(params) {
        return axios.post(base.baseUrl + base.addAppCases, params)
    },
    //  修改资讯
    modifyNews(params) {
        return axios.post(base.baseUrl + base.modifyNews, params)
    },
    //  修改案例
    modifyAppCases(params) {
        return axios.post(base.baseUrl + base.modifyAppCases, params)
    },
    //删除资讯
    deleteNews(id) {
        return axios.get(base.baseUrl + base.deleteNews, {
            params: {
                "id": id
            }
        })
    },
    //删除资讯
    deleteAppCases(id) {
        return axios.get(base.baseUrl + base.deleteAppCases, {
            params: {
                "id": id
            }
        })
    },
    //  添加新增资讯富文本图片
    addPic(params) {
        return axios.post(base.baseUrl + base.addPic, params)
    },
    //  查询设备注册结果
    queryRegisterResult(params) {
        return axios.post(base.baseUrl + base.queryRegisterResult, params)
    },
    //  设备测试数据查询
    queryTestRecord(params) {
        return axios.post(base.baseUrl + base.queryTestRecord, params)
    },
    //  单个设备测试数据查询
    queryTestRecordByMac(params) {
        return axios.post(base.baseUrl + base.queryTestRecordByMac, params)
    },
    // 测试设备数据导出
    queryTestRecordExport(params) {
        return axios.post(base.baseUrl + base.queryTestRecordExport, params, {
            responseType: "arraybuffer"
        })
    },
    // 单个测试设备数据导出
    queryTestRecordByMacExport(params) {
        return axios.post(base.baseUrl + base.queryTestRecordByMacExport, params, {
            responseType: "arraybuffer"
        })
    },
    // 获取产品下拉列表接口
    problemListForPull(params) {
        return axios.post(base.baseUrl + base.problemListForPull, params)
    },
    //设备升级-获取版本下拉列表
    versionSelectorList(params) {
        return axios.get(base.baseUrl + base.versionSelectorList, params)
    },
    // 设备升级
    versionUpgrade(params) {
        return axios.post(base.baseUrl + base.versionUpgrade, params)
    },
    // 设备升级列表
    upgradeResult(params) {
        return axios.post(base.baseUrl + base.upgradeResult, params)
    },
    // 提交工单-获取用户手机号
    getUserMobile(username) {
        return axios.get(base.baseUrl + base.getUserMobile, {
            params: {
                "username": username
            }
        })
    },
    // 提交工单
    submitWorkOrder(params) {
        return axios.post(base.baseUrl + base.submitWorkOrder, params)
    },
    // 工单列表
    workOrderList(params) {
        return axios.post(base.baseUrl + base.workOrderList, params)
    },
    // 工单详情
    workOrderDetail(id) {
        return axios.get(base.baseUrl + base.workOrderDetail, {
            params: {
                "id": id
            }
        })
    },
    // 工单处理流程
    workOrderProcess(id) {
        return axios.get(base.baseUrl + base.workOrderProcess, {
            params: {
                "id": id
            }
        })
    },
    // 客户处理工单问题
    workOrderHandle(params) {
        return axios.post(base.baseUrl + base.workOrderHandle, params)
    },
    // 修改工单联系人手机号
    updateWorkOrderPhone(params) {
        return axios.post(base.baseUrl + base.updateWorkOrderPhone, params)
    },
    //工单问题类型下拉框
    selectorForWorkOrder(params) {
        return axios.get(base.baseUrl + base.selectorForWorkOrder, params)
    },
    //工单问题状态下拉框
    selectorToWorkorder(params) {
        return axios.get(base.baseUrl + base.selectorToWorkorder, params)
    },
    // 问题类型下拉框
    problemTypeSelector(templateId) {
        return axios.get(base.baseUrl + base.problemTypeSelector, {
            params: {
                "templateId": templateId
            }
        })
    },
    // 根据问题类型获取默认处理人
    getHandlerUserByType(typeId) {
        return axios.get(base.baseUrl + base.getHandlerUserByType, {
            params: {
                "typeId": typeId
            }
        })
    },
    //问题回派
    problemRepatriate(params) {
        return axios.post(base.baseUrl + base.problemRepatriate, params)
    },
    //更新版本
    upgradeStatusUpdate(params) {
        return axios.post(base.baseUrl + base.upgradeStatusUpdate, params)
    },
    //查询用户对应的设备品类
    deviceCategory(params) {
        return axios.post(base.baseUrl + base.deviceCategory, params)
    },
    //设备详情
    sunshDeviceData(params) {
        return axios.post(base.baseUrl + base.sunshDeviceData, params)
    },
    //设备电气火灾-历史数据
    sunshDeviceHistory(params) {
        return axios.post(base.baseUrl + base.sunshDeviceHistory, params)
    },
    //历史数据接口，失败记录
    deviceFailureRecord(params) {
        return axios.post(base.baseUrl + base.deviceFailureRecord, params)
    },
    //新增第三方应用信息
    addThirdPlatformAppData(params) {
        return axios.post(base.baseUrl + base.addThirdPlatformAppData, params)
    },
    //删除第三方应用信息
    delThirdPlatformAppData(params) {
        return axios.post(base.baseUrl + base.delThirdPlatformAppData, params)
    },
    //修改第三方应用信息
    editThirdPlatformAppData(params) {
        return axios.post(base.baseUrl + base.editThirdPlatformAppData, params)
    },
    //查询第三方应用信息
    getThirdPlatformAppData(params) {
        return axios.get(base.baseUrl + base.getThirdPlatformAppData, params)
    },
    //重新生成Aes128Key
    regenerateAes128Key(params) {
        return axios.get(base.baseUrl + base.regenerateAes128Key, params)
    },
    //重新生成appSecret信息
    regenerateThirdPlatformAppSecret(params) {
        return axios.get(base.baseUrl + base.regenerateThirdPlatformAppSecret, params)
    },
    //设备列表动态url
    deviceApiUrl(apiUrl, params) {
        return axios.post(base.baseUrl + apiUrl, params)
    },
    //卡资料信息

    cardDataInfo(accessNumber) {
        return axios.get(base.baseUrl + base.cardDataInfo, {
            params: {
                "accessNumber": accessNumber
            }
        })
    },
    //根据imei获取卡号信息
    cardInfo(imei) {
        return axios.get(base.baseUrl + base.cardInfo, {
            params: {
                "imei": imei
            }
        })
    },

    //设备imei查询
    getImei(params) {
        return axios.post(base.baseUrl + base.getImei, params)
    },
    //三码互查
    telephonePlus(params) {
        return axios.post(base.baseUrl + base.telephonePlus, params)
    },
    //百度语音token获取接口
    baiduVoiceToken(params) {
        return axios.get(base.baseUrl + base.BaiduVoiceToken, params)
    },
    //解决方案
    solutionList(params) {
        return axios.post(base.baseUrl + base.SolutionList, params)
    },
    //小程序新增商品
    addGoods(params) {
        return axios.post(base.baseUrl + base.addGoods, params)
    },
    //小程序列表展示
    listGoods(params) {
        return axios.post(base.baseUrl + base.listGoods, params)
    },
    categoryListGoods(params) {
        return axios.post(base.baseUrl + base.categoryListGoods, params)
    },

    //小程序列表更新
    goodsUpdate(params) {
        return axios.post(base.baseUrl + base.GoodsUpdate, params)
    },
    //小程序列表更新
    goodsVideoUpdate(params) {
        return axios.post(base.baseUrl + base.GoodsVideoUpload, params,{
            headers: {
                "content-Type": 'multipart/form-data',
            },

        })
    },
    //小程序列表详情
    goodsDetail(id) {
        return axios.get(base.baseUrl + base.GoodsDetail, {
            params: {
                "id": id
            }
        })
    },
    //小程序列表d导出
    GoodsExport(params) {
        return axios.post(base.baseUrl + base.GoodsExport, params, {
            responseType: "arraybuffer"
        })
    },
    // 小程序列表删除
    GoodsDelete(id) {
        return axios.get(base.baseUrl + base.GoodsDelete, {
            params: {
                "id": id
            }
        })
    },
    // 小程序列表删除
    CategoryGoodsDelete(id) {
        return axios.get(base.baseUrl + base.CategoryGoodsDelete, {
            params: {
                "id": id
            }
        })
    },
    // 小程序列表删除
    dispatchGoods(params) {
        return axios.post(base.baseUrl + base.dispatchGoods, params)
    },
    //小程序兑换码列表
    CodeList(params) {
        return axios.post(base.baseUrl + base.CodeList, params)
    },
    //小程序兑换码导出
    CodeExport(params) {
        return axios.post(base.baseUrl + base.CodeExport, params, {
            responseType: "arraybuffer"
        })
    },
    //小程序生成兑换码
    GenerateCode(params) {
        return axios.post(base.baseUrl + base.GenerateCode, params)
    },

    //兑换商品列表下拉框
    GoodsListPull(params) {
        return axios.post(base.baseUrl + base.GoodsListPull, params)
    },
    //获取订单详情
    GoodsOrderDetail(params) {
        return axios.get(base.baseUrl + base.goodsOrderDetail, params)
    },
    //获取订单列表
    GoodsOrderList(params) {
        return axios.post(base.baseUrl + base.goodsOrderList, params)
    },
    //订单导出
    GoodsOrderExport(params) {
        return axios.post(base.baseUrl + base.goodsOrderExport, params, {
            responseType: "arraybuffer"
        })
    },
    //查询快递编码--支持快递名称查询
    GetDeliveryCodes(params) {
        return axios.post(base.baseUrl + base.getDeliveryCodes, params)
    },
    //查询物流信息
    getTracks(params) {
        return axios.post(base.baseUrl + base.getTracks, params)
    },
    //新增物流信息
    addTracks(params) {
        return axios.post(base.baseUrl + base.addTracks, params)
    },
    //获取模板节点配置信息
    templateAndNode(params) {
        return axios.get(base.baseUrl + base.templateAndNode, params)
    },
    //兑换码详情
    exchangeDetail(params) {
        return axios.get(base.baseUrl + base.exchangeDetail, params)
    },
    //卖出兑换码支持批量
    saleCode(params) {
        return axios.post(base.baseUrl + base.saleCode, params)
    },
    //兑换统计
    codeStatistics(params) {
        return axios.post(base.baseUrl + base.codeStatistics, params)
    },
    //订单统计
    orderStatistics(params) {
        return axios.post(base.baseUrl + base.orderStatistics, params)
    },
    //
    goodsAddPic(params) {
        return axios.post(base.baseUrl + base.goodsAddPic, params,{
            headers: {
                "content-Type": 'multipart/form-data',
            },
        })
    },
    //修改订单的物流信息
    modifyTracks(params) {
        return axios.post(base.baseUrl + base.modifyTracks, params)
    },
    //添加商城广告函数
    mallAddAdvertisementUrl(params) {
        return axios.post(base.baseUrl + base.mallAddAdvertisementUrl, params, {
                headers: {
                    "content-Type": 'multipart/form-data',
                },
            }

        )
    },
    //商城广告列表展示函数
    mallAdvertisementListUrl(params) {
        return axios.post(base.baseUrl + base.mallAdvertisementListUrl, params)
    },
    //商城广告列表删除函数
    mallAdvertisementDeleteUlr(deleteId) {
        return axios.get(base.baseUrl + base.mallAdvertisementDeleteUlr, {
            params: {
                id: deleteId
            }
        })
    },
    //商城广告列表修改函数
    mallAdvertisementEditUrl(params) {
        return axios.post(base.baseUrl + base.mallAdvertisementEditUrl, params, {
            headers: {
                "content-Type": 'multipart/form-data',
            },
        })
    },
    //创建设备组
    deviceGroup(params) {
        return axios.post(base.baseUrl + base.deviceGroup, params)
    },
    //短信发送折线图
    analysisSms(params) {
        return axios.post(base.baseUrl + base.analysisSms, params)
    },
    //语音发送折线图
    analysisVoice(params) {
        return axios.post(base.baseUrl + base.analysisVoice, params)
    },
    //设备微信登录展示
    setWechatLoginShow(isShow) {
        return axios.get(base.baseUrl + base.setWechatLoginShow, {
            params: {
                isShow: isShow
            }
        })
    },
    //获获取微信登录展示状态
    getWechatLoginShow(params) {
        return axios.get(base.baseUrl + base.getWechatLoginShow, params)
    },

    //获获取微信登录展示状态
    deviceRssiMap(params) {
        return axios.post(base.baseUrl + base.DeviceRssiMap, params)
    },
    //信号强度饼图
    analysisRssi(params) {
        return axios.post(base.baseUrl + base.analysisRssi, params)
    },
    //电量正常异常饼图统计
    analysisBattery(params) {
        return axios.post(base.baseUrl + base.analysisBattery, params)
    },
    //7日电量数据统计
    analysisBatteryWeek(params) {
        return axios.post(base.baseUrl + base.analysisBatteryWeek, params)
    },
    //7日温度数据统计
    analysisTemperature(params) {
        return axios.post(base.baseUrl + base.analysisTemperature, params)
    },
    //wifi已注册设备列表
    wifiRegisterList(params) {
        return axios.post(base.baseUrl + base.wifiRegisterList, params)
    },

    //wifi注册设备到iot
    wifiRegistDevice(params) {
        return axios.post(base.baseUrl + base.wifiRegistDevice, params)
    },
    //从iot删除设备--WIFI
    wifiDeleteDevices(params) {
        return axios.post(base.baseUrl + base.wifiDeleteDevices, params)
    },
    //从iot删除设备--WIFI
    wifiUpdateDevices(params) {
        return axios.post(base.baseUrl + base.wifiUpdateDevices, params)
    },
    //wifi设备注册列表导出文件
    exportDeviceRegister(params) {
        return axios.post(base.baseUrl + base.exportDeviceRegister, params, {
            responseType: "arraybuffer"
        })
    },

    //添加广告列表-
    addPicMall(params) {
        return axios.post(base.baseUrl + base.addPicMall, params)
    },
    //添加广告列表
    piclistMall(params) {
        return axios.post(base.baseUrl + base.piclistMall, params)
    },
    //APP新闻资讯
    newsListApp(params) {
        return axios.post(base.baseUrl + base.newsListApp, params)
    },
    //APP解决方案
    solutionListApp(params) {
        return axios.post(base.baseUrl + base.solutionListApp, params)
    },

    //新增mqtt设备
    mqttDeviceAdd(params) {
        return axios.post(base.baseUrl + base.MqttDeviceAdd, params)
    },
    //批量新增mqtt设备
    mqttDeviceBatchAdd(params) {
        return axios.post(base.baseUrl + base.MqttDeviceBatchAdd, params)
    },
    //导出mqtt设备
    mqttDeviceExport(params) {
        return axios.post(base.baseUrl + base.MqttDeviceExport, params, {
            responseType: "arraybuffer"
        })
    },
    //mqtt设备列表
    mqttDevicelist(params) {
        return axios.post(base.baseUrl + base.MqttDevicelist, params)
    },
    // mqtt设备列表删除
    mqttDeviceDelete(id) {
        return axios.get(base.baseUrl + base.MqttDeviceDelete, {
            params: {
                "id": id
            }
        })
    },
    //手机号登录获取验证码
    getLoginCode(id) {
        return axios.get(base.baseUrl + base.GetLoginCode, {
            params: {
                mobile: id
            }
        })
    },
    //卡信息更新
    chinanetCardupdate(params) {
        return axios.post(base.baseUrl + base.chinanetCardupdate, params)
    },
    //卡续费提醒
    cardRechargeNotify(params) {
        return axios.post(base.baseUrl + base.cardRechargeNotify, params)
    },
    //通知配置列表
    cardRechargeNotifyList(params) {
        return axios.get(base.baseUrl + base.cardRechargeNotifyList, params)
    },
    //充值记录列表
    cardRechargeRecordList(params) {
        return axios.post(base.baseUrl + base.cardRechargeRecordList, params)
    },
    //卡充值添加规则
    cardRechargeRulesAdd(params) {
        return axios.post(base.baseUrl + base.cardRechargeRulesAdd, params)
    },
    //卡充值规则列表
    cardRechargeRulesList(params) {
        return axios.post(base.baseUrl + base.cardRechargeRulesList, params)
    },
    //卡充值修改规则
    cardRechargeRulesUpdate(params) {
        return axios.post(base.baseUrl + base.cardRechargeRulesUpdate, params)
    },
    // 卡充值规则删除
    cardRechargeRulesDelete(id) {
        return axios.get(base.baseUrl + base.cardRechargeRulesDelete, {
            params: {
                "id": id
            }
        })
    },
    //卡充值记录导出
    cardRechargeRecordExport(params) {
        return axios.post(base.baseUrl + base.cardRechargeRecordExport, params, {
            responseType: "arraybuffer"
        })
    },
    //卡充值完成通知
    cardCompleteNotify(params) {
        return axios.post(base.baseUrl + base.cardCompleteNotify, params)
    },
    //文件列表
    fileManagementList(params) {
        return axios.post(base.baseUrl + base.fileManagementList, params)
    },
    //文件列表
    fileManagementListWeb(params) {
        return axios.post(base.baseUrl + base.fileManagementListWeb, params)
    },
    //文件列表
    fileManagementAdd(params) {
        return axios.post(base.baseUrl + base.fileManagementAdd, params)
    },
    //文件列表
    fileManagementWebAdd(params) {
        return axios.post(base.baseUrl + base.fileManagementWebAdd, params)
    },
    // 文件删除
    fileManagementDelete(id) {
        return axios.get(base.baseUrl + base.fileManagementDelete, {
            params: {
                "id": id
            }
        })
    },
    getFileCategoryList() {
        return axios.get(base.baseUrl + base.fileCategoryList,)
    },
    //文件列表编辑
    fileManagementUpdate(params) {
        return axios.post(base.baseUrl + base.fileManagementUpdate, params)
    },
    //文件列表编辑
    fileManagementUpdateWeb(params) {
        return axios.post(base.baseUrl + base.fileManagementUpdateWeb, params)
    },
    //版本管理新增更新提示
    versionUpTipsAdd(params) {
        return axios.post(base.baseUrl + base.versionUpTipsAdd, params)
    },
    // 版本管理删除更新提示
    versionUpTipsDelete(id) {
        return axios.get(base.baseUrl + base.versionUpTipsDelete, {
            params: {
                "id": id
            }
        })
    },
    //版本管理更新提示列表
    versionUpTipList(params) {
        return axios.post(base.baseUrl + base.versionUpTipList, params)
    },
    //版本管理版本更新提示（登录）
    upTipsForLogin(params) {
        return axios.get(base.baseUrl + base.upTipsForLogin, params)
    },
    //版本管理修改更新提示
    versionUpTipUpdate(params) {
        return axios.post(base.baseUrl + base.versionUpTipUpdate, params)
    },
    //远程消音
    remoteSilencing(params) {
        return axios.post(base.baseUrl + base.remoteSilencing, params)
    },
    // 查询第三方应用限频信息列表
    thirdPlatformAppLimitDataList(params) {
        return axios.post(base.baseUrl + base.thirdPlatformAppLimitDataList, params)
    },
    //修改第三方应用限频信息
    editThirdPlatformAppLimitData(params) {
        return axios.post(base.baseUrl + base.editThirdPlatformAppLimitData, params)
    },
    //积分补赠单个用户
    singleAddPoints(params) {
        return axios.post(base.baseUrl + base.singleAddPoints, params)
    },
    //补增列表
    addPointsList(params) {
        return axios.post(base.baseUrl + base.addPointsList, params)
    },
    //补增列表
    addPoints(params) {
        return axios.post(base.baseUrl + base.addPoints, params)
    },

    //题目列表
    integralquestionList(params) {
        return axios.post(base.baseUrl + base.integralquestionList, params)
    },
    //门店列表
    shopList(params) {
        return axios.post(base.baseUrl + base.shopList, params)
    },
    //商品评论列表
    evaluateList(params) {
        return axios.post(base.baseUrl + base.evaluateList, params)
    },
    //添加评论
    addEvaluate(params) {
        return axios.post(base.baseUrl + base.addEvaluate, params)
    },
    //题目新增
    integralquestionAdd(params) {
        return axios.post(base.baseUrl + base.integralquestionAdd, params)
    },
    //门店单个新增
    shopAdd(params) {
        return axios.post(base.baseUrl + base.shopAdd, params)
    },
    //门店信息修改
    shopUpdate(params) {
        return axios.post(base.baseUrl + base.shopUpdate, params)
    },
    //设备绑定信息修改
    deviceBindInfoUpdate(params) {
        return axios.post(base.baseUrl + base.deviceInfoUpdate, params)
    },
    //题目修改
    integralquestionUpdate(params) {
        return axios.post(base.baseUrl + base.integralquestionUpdate, params)
    },
    // 题目删除
    integralquestionDelete(id) {
        return axios.get(base.baseUrl + base.integralquestionDelete, {
            params: {
                "id": id
            }
        })
    },
    //门店删除
    shopDelete(id) {
        return axios.get(base.baseUrl + base.shopDelete, {
            params: {
                "id": id
            }
        })
    },
    //门店删除
    evaluateDelete(id) {
        return axios.get(base.baseUrl + base.evaluateDelete, {
            params: {
                "id": id
            }
        })
    },
    //置顶评价
    setTop(params) {
        return axios.post(base.baseUrl + base.setTop,params)
    },
    //审核评论
    allowShow(params) {
        return axios.post(base.baseUrl + base.allowShow,params)
    },
    //批量上传题目
    integralquestionImport(params) {
        return axios.post(base.baseUrl + base.integralquestionImport, params)
    },
    //批量导入设备绑定信息
    deviceInfoImport(params) {
        return axios.post(base.baseUrl + base.deviceInfoImport, params)
    },
    //批量上传店铺
    shopListImport(params) {
        return axios.post(base.baseUrl + base.shopListImport, params)
    },
    //批量上传卡信息
    teleCardNumImport(params) {
        return axios.post(base.baseUrl + base.teleCardNumImport, params)
    },
      //用户紧急联系人列表
      projectLinkManList(params) {
        return axios.post(base.baseUrl + base.projectLinkManList, params)
    },
     //用户添加紧急联系人
     addProjectLinkMan(params) {
        return axios.post(base.baseUrl + base.addProjectLinkMan, params)
    },
     // 用户紧急联系人删除
     deleteProjectLinkMan(id) {
        return axios.get(base.baseUrl + base.deleteProjectLinkMan, {
            params: {
                "id": id
            }
        })
    },
     //发送添加紧急联系人验证码
     getProjectLinkManCode(mobile) {
        return axios.get(base.baseUrl + base.getProjectLinkManCode, {
            params: {
                "mobile": mobile
            }
        })
    },
      //小程序活动列表
      activityList(params) {
        return axios.post(base.baseUrl + base.activityList, params)
    },
    //商品类别列表
    goodsTypeList(params) {
        return axios.post(base.baseUrl + base.goodsTypeList, params)
    },
     //小程序活动创建活动
     activityAdd(params) {
        return axios.post(base.baseUrl + base.activityAdd, params)
    },
    //小程序活动创建活动
    goodsTypeAdd(params) {
        return axios.post(base.baseUrl + base.goodsTypeAdd, params)
    },
    goodsTypeEdit(params) {
        return axios.post(base.baseUrl + base.goodsTypeEdit, params)
    },
       // 小程序活动删除活动
       activityDelete(id) {
        return axios.get(base.baseUrl + base.activityDelete, {
            params: {
                "id": id
            }
        })
    },
    // 商城商品分类删除
    goodsCategoryDelete(id) {
        return axios.get(base.baseUrl + base.goodsTypeDel, {
            params: {
                "id": id
            }
        })
    },
    //小程序分享列表
    shareList(params) {
        return axios.post(base.baseUrl + base.shareList, params)
    },
    //小程序创建分享
    shareAdd(params) {
        return axios.post(base.baseUrl + base.shareAdd, params)
    },
    //小程序分享修改
    shareUpdate(params) {
        return axios.post(base.baseUrl + base.shareUpdate, params)
    },
    // 小程序分享删除
    shareDelete(id) {
        return axios.get(base.baseUrl + base.shareDelete, {
            params: {
                "id": id
            }
        })
    },
     //小程序新增奖品
     prizeAdd(params) {
        return axios.post(base.baseUrl + base.prizeAdd, params)
    },
      //小程序奖品列表
      prizeList(params) {
        return axios.post(base.baseUrl + base.prizeList, params)
    },
      //小程序修改奖品
      prizeUpdate(params) {
        return axios.post(base.baseUrl + base.prizeUpdate, params)
    },
       // 小程序奖品删除
       prizeDelete(id) {
        return axios.get(base.baseUrl + base.prizeDelete, {
            params: {
                "id": id
            }
        })
    },
    //小程序奖品下拉列表
    prizeSelectorList(params) {
        return axios.get(base.baseUrl + base.prizeSelectorList, params)
    },
      //小程序列表活动奖品
      activityPrizeList(activityCode) {
        return axios.get(base.baseUrl + base.activityPrizeList, {
             params: {
            "activityCode": activityCode
        }})
    },
     // 小程序奖品删除 活动奖品
     activityPrizeDelete(id) {
           return axios.get(base.baseUrl + base.activityPrizeDelete, {
            params: {
                "id": id
            }
        })
    },

     //小程序新增奖品
     activityPrizeAdd(params) {
        return axios.post(base.baseUrl + base.activityPrizeAdd, params)
    },
      //小程序修改奖品
      activityPrizeUpdate(params) {
        return axios.post(base.baseUrl + base.activityPrizeUpdate, params)
    },
      //小程序用户奖品详情
      userPrizeDetail(id) {
        return axios.get(base.baseUrl + base.userPrizeDetail, {
         params: {
             "id": id
         }
     })
 },
   //小程序用户奖品导出
   userPrizeExport(params) {
    return axios.post(base.baseUrl + base.userPrizeExport, params, {
        responseType: "arraybuffer"
    })
},
 //小程序用户奖品列表
 userPrizeList(params) {
    return axios.post(base.baseUrl + base.userPrizeList, params)
},
// 移动卡信息查询
chinamobile(imei) {
    return axios.get(base.baseUrl + base.chinamobile, {
        params: {
            "imei": imei
        }
    })
},

   //小程序帮助中心类型
   categoryList(params) {
    return axios.post(base.baseUrl + base.categoryList, params)
},
 //小程序新增帮助中心类型
 categoryAdd(params) {
    return axios.post(base.baseUrl + base.categoryAdd, params)
},
 //小程序修改帮助中心类型
 categoryUpdate(params) {
    return axios.post(base.baseUrl + base.categoryUpdate, params)
},
// 移动卡信息查询
categoryDelete(id) {
    return axios.get(base.baseUrl + base.categoryDelete, {
        params: {
            "id": id
        }
    })
},
 //小程序帮助中心列表
 helpCenterlist(params) {
    return axios.post(base.baseUrl + base.helpCenterlist, params)
},
 //小程序帮助中心新增
 helpCenterAdd(params) {
    return axios.post(base.baseUrl + base.helpCenterAdd, params)
},
 //小程序帮助中心修改
 helpCenterUpdate(params) {
    return axios.post(base.baseUrl + base.helpCenterUpdate, params)
},
// 小程序帮助中心删除
helpCenterDelete(id) {
    return axios.get(base.baseUrl + base.helpCenterDelete, {
        params: {
            "id": id
        }
    })
},
// 小程序帮助中心详情
helpCenterInfo(id) {
    return axios.get(base.baseUrl + base.helpCenterInfo, {
        params: {
            "id": id
        }
    })
},
 //用户行为分析统计行为列表
 statisticsBehaviorList(params) {
    return axios.post(base.baseUrl + base.statisticsBehaviorList, params)
},
//用户行为分析统计行为修改
statisticsBehaviorUpdate(params) {
    return axios.post(base.baseUrl + base.statisticsBehaviorUpdate, params)
},
//用户行为分析统计行为新增
statisticsBehavioAdd(params) {
    return axios.post(base.baseUrl + base.statisticsBehavioAdd, params)
},
// 用户行为分析统计行为删除
statisticsBehavioDelete(id) {
    return axios.get(base.baseUrl + base.statisticsBehavioDelete, {
        params: {
            "id": id
        }
    })
},
//访问量统计列表
statisticsVisitsList(params) {
    return axios.post(base.baseUrl + base.statisticsVisitsList, params)
},
//访问量统计详情
statisticsVisitsDetail(params) {
    return axios.post(base.baseUrl + base.statisticsVisitsDetail, params)
},

   //用户访问日志
   userAccess(params) {
    return axios.get(base.baseUrl + base.UserAccess, params)
},

//设备产测记录列表
iotQcWifiList(params) {
    return axios.post(base.baseUrl + base.iotQcWifiList, params)
},
   //导出文件
   devTestResultExport(params) {
    return axios.post(base.baseUrl + base.devTestResultExport, params, {
        responseType: "arraybuffer"
    })
},
// 电信卡导出
chinanetCardExport(params) {
    return axios.post(base.baseUrl + base.chinanetCardExport, params, {
        responseType: "arraybuffer"
    })
},
//设备产测记录列表
statisticsSmsList(params) {
    return axios.post(base.baseUrl + base.statisticsSmsList, params)
},
//招商页面新增信息收集
attractInvestmentAdd(params) {
    return axios.post(base.baseUrl + base.attractInvestmentAdd, params)
},

//新增程序版本
versionProgramAdd(params) {
    return axios.post(base.baseUrl + base.versionProgramAdd, params)
},
//程序版本列表
versionProgramList(params) {
    return axios.post(base.baseUrl + base.versionProgramList, params)
},
//程序版本修改
versionProgramUpdate(params) {
    return axios.post(base.baseUrl + base.versionProgramUpdate, params)
},
// 程序版本删除
versionProgramDelete(id) {
    return axios.get(base.baseUrl + base.versionProgramDelete, {
        params: {
            "id": id
        }
    })
},
// 程序版本下拉列表
versionProgramSelector(netOperator) {
    return axios.get(base.baseUrl + base.versionProgramSelector, {
        params: {
            "netOperator": netOperator
        }
    })
},

//产品指标管理新增
productPointAdd(params) {
    return axios.post(base.baseUrl + base.productPointAdd, params)
},
//产品指标管理新增
productPointList(params) {
    return axios.post(base.baseUrl + base.productPointList, params)
},

//产品指标管理修改
productPointUpdate(params) {
    return axios.post(base.baseUrl + base.productPointUpdate, params)
},
//产品指标管理删除
productPointDelete(params) {
    return axios.post(base.baseUrl + base.productPointDelete, params)
},
// 官网产品配置
sysNewsProductList(params) {
    return axios.post(base.baseUrl + base.sysNewsProductList, params)
},
productListForApp(params){
    return axios.post(base.baseUrl + base.productListForApp, params)
},
// 产品列表展示--类型列表
productListForCategory(params) {
    return axios.post(base.baseUrl + base.productListForCategory, params)
},
// 官网首页产品配置
sysNewsProductListhome(params) {
    return axios.post(base.baseUrl + base.sysNewsProductListhome, params)
},

// 移动官网首页产品配置
productListForAppHome(params) {
    return axios.post(base.baseUrl + base.productListForAppHome, params)
},
}

export default api;

<template>
    <div class="outting">
        <div class="select">
            <span class="title">活动奖品列表</span>
               <el-button type="text" size="small" icon="el-icon-back" @click="gobackPlaceDetail"
                    class="addRoleButton">返回活动列表</el-button>
            <el-button type="text" size="small" icon="el-icon-plus" @click="addUserHandler" class="addRoleButton">新建奖品活动
            </el-button>
        </div>
        <div class="table-vv">
            <el-table :data="tableData" style="width: 100%" stripe size="small"
                :header-cell-style="{'background-color': '#eeeeee'}">
                 <el-table-column prop="prizeName" label=" 奖品名称" min-width="2" fixed align="center"></el-table-column>
                <el-table-column prop="serialNumber" label="排序号" min-width="1" fixed align="center">
                     <template slot-scope="scope">
                        <el-input style="width:5rem" type="text" v-model="scope.row.serialNumber" v-show="scope.row.iseditor"
                            size="mini"> </el-input>
                        <span v-show="!scope.row.iseditor">{{scope.row.serialNumber}}</span>
                    </template>
                </el-table-column>
                   <el-table-column prop="activityPrizeSurplusStock" label="剩余库存" min-width="2" fixed align="center"></el-table-column>
              <el-table-column prop="relateGoodsName" label="关联商品" min-width="2" fixed align="center"></el-table-column>
                <el-table-column prop="activityPrizeStock" label="活动奖品库存" min-width="2" fixed align="center">
                       <template slot-scope="scope">
                        <el-input style="width:5rem" type="text" v-model="scope.row.activityPrizeStock" v-show="scope.row.iseditor"
                            size="mini"> </el-input>
                        <span v-show="!scope.row.iseditor">{{scope.row.activityPrizeStock}}</span>
                    </template>
                </el-table-column>

                  <el-table-column prop="prizeValidityType" label="奖品有效期类型" min-width="2" fixed align="center">
                    <template
                        slot-scope="{row}">{{ row.prizeValidityType  == 0?'固定日期': '中奖后多少天'}}</template>
                </el-table-column>
              <el-table-column prop="shareLinkUrl" label="小程序领取链接" min-width="4" fixed align="center">

              </el-table-column>
                <el-table-column prop="redeemPoints" label="兑换积分" min-width="1" fixed align="center">
                     <template slot-scope="scope">
                        <el-input style="width:5rem" type="text" v-model="scope.row.redeemPoints" v-show="scope.row.iseditor"
                            size="mini"> </el-input>
                        <span v-show="!scope.row.iseditor">{{scope.row.redeemPoints}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="fixedDate" label="有效期固定日期" min-width="2" fixed align="center"></el-table-column>

                <!-- <el-table-column prop="serialNumber" label="排序号" min-width="2" fixed align="center"></el-table-column> -->
                <el-table-column prop="validityDay" label="有效天数" min-width="1" fixed align="center"></el-table-column>
                <el-table-column prop="winProbability" label="中奖概率" min-width="2" fixed align="center">
                      <template slot-scope="scope">
                        <el-input style="width:5rem" type="text" v-model="scope.row.winProbability" v-show="scope.row.iseditor"
                            size="mini"> </el-input>
                        <span v-show="!scope.row.iseditor">{{scope.row.winProbability}}</span>
                    </template>
                </el-table-column>
                          <el-table-column prop="winningRestrictionRules" label="中奖限制规则" min-width="3" fixed align="center">
                    <template slot-scope="scope">
                        <el-select  size="mini"  v-model="scope.row.winningRestrictionRules" placeholder="请选中奖限制规则" v-show="scope.row.iseditor== true && scope.row.winningRestrictionRules != null"  >
                        <el-option label="活动周期内" value="1"></el-option>
                        <el-option label="每天" value="2"></el-option>
                        <el-option label="奖品有效期内未使用" value="3"></el-option>
                        <el-option label="奖品有效期内已使用或可使用" value="4"></el-option>
                    </el-select>
                        <span v-show="!scope.row.iseditor">{{ scope.row.winningRestrictionRules  == 1?'活动周期内': scope.row.winningRestrictionRules  == 2 ?'每天': scope.row.winningRestrictionRules  == 3?'奖品有效期内未使用':scope.row.winningRestrictionRules  == 4?"奖品有效期内已使用或可使用":''}}</span>
                   </template>
                    <!--  <template
                        slot-scope="{row}"></template> -->
                </el-table-column>
                <el-table-column prop="winningRestrictionAmount" label="中奖限制数量" min-width="1" fixed align="center">
                     <template slot-scope="scope">
                        <el-input style="width:5rem" type="text" v-model="scope.row.winningRestrictionAmount" v-show="scope.row.iseditor"
                            size="mini"> </el-input>
                        <span v-show="!scope.row.iseditor">{{scope.row.winningRestrictionAmount}}</span>
                    </template>
                </el-table-column>

                <!-- <el-table-column prop="createTime" label="创建时间" min-width="2" fixed align="center"></el-table-column> -->
                <el-table-column min-width="1" label="操作" width="180" align="center">
                    <template slot-scope="scope">

                         <el-button @click="save(scope.row)" v-show="scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">修改
                        </el-button>
                        <el-button @click="edit(scope.row,scope)" v-show="!scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">编辑
                        </el-button>
                        <el-popconfirm title="确定删除这个奖品吗？" @onConfirm="deleteAddHandler()">
                            <el-button type="text" size="small" icon="el-icon-delete"
                                @click="handleDelete(scope.$index, scope.row)" style="margin-left:10px;"
                                slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <el-pagination background layout="prev, pager, next" :total="total" class="pagin"
                @current-change="changeCurrent"></el-pagination> -->
        </div>
        <el-dialog title="新增" :visible.sync="addDialogFormVisible" :close-on-click-modal="false">
            <el-form :model="addForm" size="small" style="70%" :rules="addRules" ref="addRules"
                enctype="multipart/form-data">
                <el-form-item label="奖品" :label-width="formLabelWidth" prop="prizeId">
                     <el-select v-model="addForm.prizeId"  @change="availableStockFn()"  placeholder="请选奖品类型" style="width:315px">
                        <el-option    v-for="(item,index) in SelectorList" :key="index" :label="item.prizeName" :value="item.id"></el-option>
                    </el-select>
                   <span>可用库存：{{availableStock }}</span>
                </el-form-item>
                <el-form-item label="奖品有效期类型" :label-width="formLabelWidth" prop="prizeValidityType">
                    <el-select v-model="addForm.prizeValidityType" placeholder="请选奖品有效期类型" style="width:315px">
                        <el-option label="固定日期" value="0"></el-option>
                        <el-option label="中奖后多少天" value="1"></el-option>
                    </el-select>
                </el-form-item>
                  <el-form-item label="有效期固定日期" v-if="addForm.prizeValidityType == '0'"  prop="fixedDate" :label-width="formLabelWidth" >
                                    <el-date-picker v-model="addForm.fixedDate "    value-format="yyyy-MM-dd HH:mm:ss"
                                        default-time="23:59:59"   type="datetime"  format="yyyy-MM-dd HH:mm:ss"  placeholder="选择有效期固定日期">
                                    </el-date-picker>
                                </el-form-item>
                  <el-form-item label="有效天数"  v-if="addForm.prizeValidityType == '1'"   :label-width="formLabelWidth" prop="validityDay">
                    <el-input v-model="addForm.validityDay"    placeholder="请输入有效天数"   onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" style="width:315px">
                    </el-input>
                </el-form-item>
                <el-form-item label="活动奖品库存" :label-width="formLabelWidth" prop="activityPrizeStock">
                    <el-input v-model="addForm.activityPrizeStock"   placeholder="请输入活动奖品库存"    onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" style="width:315px">
                    </el-input>
                </el-form-item>
                  <el-form-item label="序号" :label-width="formLabelWidth" prop="serialNumber">
                    <el-input v-model="addForm.serialNumber"  placeholder="请输入序号"      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" style="width:315px">
                    </el-input>
                </el-form-item>
                      <el-form-item label="兑换积分" prop="redeemPoints" :label-width="formLabelWidth">
                                    <el-input v-model.trim="addForm.redeemPoints"  style="width:315px"
                                        placeholder="请输入兑换积分"></el-input>
                                </el-form-item>

                 <el-form-item label="中奖概率(%)" prop="winProbability" :label-width="formLabelWidth">
                                    <el-input v-model.trim="addForm.winProbability"  style="width:315px"
                                        placeholder="请输入中奖概率(%)"></el-input>
                                </el-form-item>
                                   <el-form-item    label="中奖限制规则" :label-width="formLabelWidth" prop="winningRestrictionRules">
                    <el-select v-model="addForm.winningRestrictionRules " placeholder="请选中奖限制规则" style="width:315px">
                        <el-option label="活动周期内" value="1"></el-option>
                        <el-option label="每天" value="2"></el-option>
                        <el-option label="奖品有效期内未使用" value="3"></el-option>
                        <el-option label="奖品有效期内已使用或可使用" value="4"></el-option>
                    </el-select>
                </el-form-item>
                  <el-form-item label="中奖限制数量" :label-width="formLabelWidth" prop="winningRestrictionAmount">
                    <el-input v-model="addForm.winningRestrictionAmount"   placeholder="请选中奖限制数量"   onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" style="width:315px">
                    </el-input>
                </el-form-item>
              <el-form-item label="分享链接前缀"  :label-width="formLabelWidth">
                <el-input v-model.trim="addForm.sharePrefixUrl"  style="width:415px"
                          placeholder="请输入分享链接前缀"></el-input>
              </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addAppListForm('addForm')" size="small">确 定</el-button>
            </div>
        </el-dialog>


    </div>
</template>
<script>
    export default {
        name: "prizeList",
        data() {
            return {
                dialogVisible: false,
                dialogImageUrl: "",
                current: 1,
                availableStock:"",
                 iseditor: false,
                size: 10,
                         fit: "fill",
                total: 0,
                value: "",
                tableData: [],
                 file: {},
                deleteId: "",
                editId: "",
                addDialogFormVisible: false,
                // 限制开始时间
                pickerOptionsStart: {},
                pickerOptionsEnd: {},
                addForm: {
                    prizeId : "",
                    prizeValidityType: "",
                    fixedDate: "",
                    activityPrizeStock:"",
                    serialNumber:"",
                    validityDay:"",
                    sharePrefixUrl:"https://jiayong360safe.cn/applet?id=",
                    winProbability:"",
                    redeemPoints:"",
                    winningRestrictionAmount:"",
                    winningRestrictionRules:"",
                },

                addRules: {
                    prizeId : [{
                        required: true,
                        message: "请输入活动编码",
                        trigger: "blur"
                    }],
                    prizeValidityType: [{
                        required: true,
                        message: "选择奖品有效期类型",
                        trigger: "change"
                    }],
                    fixedDate: [{
                            required: false,
                            message: "请输入有效期固定日期",
                             trigger: "blur"
                        }],
                       activityPrizeStock: [{
                            required: true,
                            message: "活动奖品库存",
                             trigger: "blur"
                        }],
                      serialNumber: [{
                        required: false,
                        message: "请输入序号",
                        trigger: "blur"
                    }],
                      validityDay: [{
                        required: false,
                        message: "请输入有效天数",
                        trigger: "blur"
                    }],
                     redeemPoints: [{
                        required: false,
                        message: "请输入兑换积分",
                        trigger: "blur"
                    }],
                      winProbability: [{
                        required: false,
                        message: "请输入中奖概率(%)",
                        trigger: "blur"
                    }, {
                            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
                            message: '请输入正确格式,可保留两位小数'
                        }],
                      winningRestrictionAmount: [{
                        required: false,
                        message: "请输入中奖限制数量",
                        trigger: "blur"
                    }],
                      winningRestrictionRules: [{
                        required: false,
                        message: "请输入中奖限制规则",
                        trigger: "blur"
                    }],
                },
                formLabelWidth: "180px",
               SelectorList:[],
            };
        },
        created() {
            this.appList();
            this.prizeSelectorList();
            // console.log(this.$route.query)
        },
        methods: {
            //小程序奖品下拉列表
            prizeSelectorList(){
                this.$api
                    .prizeSelectorList()
                    .then(res => {
                    // console.log(res.data)
                    this.SelectorList = res.data.data
                    });
            },
            availableStockFn(){
                console.log(this.addForm.prizeId)
     for (let index = 0; index < this.SelectorList.length; index++) {
       if(this.addForm.prizeId == this.SelectorList[index].id){
           this.availableStock = this.SelectorList[index].availableStock
       }

     }
            },
                  edit(row, index) {
               row.iseditor = true
            },
              //回到模块页面
            gobackPlaceDetail() {
                this.$router.push("/activityList");
            },
            save(item) {
                this.$api
                    .activityPrizeUpdate({
                        activityPrizeStock: item.activityPrizeStock,
                        winProbability: item.winProbability,
                        serialNumber: item.serialNumber,
                        winningRestrictionAmount: item.winningRestrictionAmount,
                        winningRestrictionRules: item.winningRestrictionRules,
                        id:item.id,
                        redeemPoints:item.redeemPoints
                    })
                    .then(res => {
                        if (res.data.code == 200) {

                            this.$message.success("修改成功");
                            this.appList();
                               this.iseditor = false
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            handleChange(file, fileList) {
                console.log(file.raw,file);
                this.value = file.url;
                this.file = file.raw;
                if (file.raw) {
                    this.$nextTick(() => { //等待照片上传完成，
                        this.$refs['formImg'].clearValidate()
                    })

                }
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleRemove(file, fileList) {
                // console.log(file, fileList);
                // this.fileList = fileList
                this.value = "";
                this.file = {};
            },
            //删除函数
            handleDelete(index, row) {
                this.deleteId = row.id;
            },
            //删除确定
            deleteAddHandler() {
                this.$api.activityPrizeDelete(this.deleteId).then(res => {
                    console.log(res.data);
                    if (res.data.code == 200) {
                        this.$message.success("删除成功!");
                        this.appList();
                    }
                });
            },


            //重置函数
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            appList() {
                this.$api
                    .activityPrizeList(this.$route.query.data.activityCode)
                    .then(res => {
                        console.log(res.data);
                         for (let index = 0; index < res.data.data.length; index++) {
                         res.data.data[index].iseditor = false
                         }
                        this.tableData = res.data.data;

                        // this.total = res.data.data.total;
                    });
            },
            addUserHandler() {
                this.file = {};
                this.addDialogFormVisible = true;
                if (this.$refs["addRules"] !== undefined) {
                    this.resetForm("addRules");
                }
            },
            deleteFn(item) {
                console.log(item)
                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i] == item) {
                        this.items.splice(i, 1)
                    }
                }
            },
            changeCurrent(id) {
                this.current = id;
                this.appList();
            },

            changeAppType(id) {
                // console.log(id);
                this.addappType = Number(id);
            },


            addAppListForm(formName) {
                console.log(this.addForm, this.file)

                this.$refs["addRules"].validate(valid => {
                   this.addForm.activityCode = this.$route.query.data.activityCode
                    if (valid) {

                        // if(this.addForm.prizeType == 2){
                        //      if(this.addForm.couponMinusPrice == '' ||this.addForm.couponFullPrice==''||this.addForm.winningRestrictionRules==''){
                        //            this.$message.error('请填写优惠卷相关数据');
                        //            return
                        //      }
                        // }
                        this.$api.activityPrizeAdd(this.addForm).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("创建活动成功！");
                                this.file = {};
                                this.value = ''
                                this.addDialogFormVisible = false;
                                this.appList();
                            }
                        });

                    } else {
                        return false;
                    }
                });
            },





        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .new_add {
            width: 100%;
        }

        .select-button {
            float: right;
            color: @toolBarFontColor;
            margin-top: 3px;
            margin-left: 15px;
        }

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .select-status {
                width: 9rem;
                margin-left: 1.7rem;
                margin-top: 7px;
                // margin-top:7px;
            }

            .select-input-timer {
                float: right;
            }

            .select-status {
                float: right;
            }

            .select-input-right-input {
                float: right;
                // width: 8rem;
                line-height: 46px;
            }

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .addRoleButton {
                margin-left: 15px;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }

            .update-input-timer {
                float: right;
                line-height: 46px;
                margin-left: 25px;
                height: 46px;

                .demonstrationTwo {
                    float: left;
                    font-size: 0.8rem;
                }

                .update-input-timer-select {
                    width: 11rem;
                    margin-left: 5px;
                }
            }
        }

        .table-vv {
            padding: 1rem 3rem;

            .pagin {
                margin-left: 30%;
                margin-top: 10px;
                float: right;
            }
        }
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>

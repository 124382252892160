<template>
  <div class="aboutUs">

    <div class="block">
      <div class="lunbotu">
        <!-- <el-carousel-item > -->
        <img src="../../assets/gsjjbanner.jpg" class="image" />
        <!-- </el-carousel-item> -->
      </div>
    </div>

    <div class="titleDisplay">

      <div class="major_service" >
        <div class="item-title">
          <p>公司简介</p>
          <span></span>
        </div>
        <div class="lest_us"  :style="backgroundDiv">


           <div class="center_content_us" >
          <div class="left_content_us">
            <img src="../../assets/us_yyzz.jpg" class="image" />
          </div>
          <div class="right_content">
            <p class="text_list2">
              青鸟消防股份有限公司（简称“公司”、“青鸟消防”）成立于2001年6月，是国内首家也是唯一一家以消防电子产品为主营业务的A股上市公司（证券简称：青鸟消防，证券代码：002960）。公司自成立以来始终聚焦于消防安全系统的研发、生产和销售，是国内规模最大、品种最全、技术实力最强的消防电子产品供应商。

            </p>
            <!-- <p class="text_list2">
              鸱吻喜爱在险要处东张西望，也喜欢吞火。鸱吻属厌胜之物，是古代在民俗信仰上的具体体现
            </p>
            <p class="text_list2">
              古人期望借助他们的神力来避火，在房脊上安两个相对的鸱吻，象征辟除火灾。
            </p> -->
          </div>
        </div>
           <p class="text_list">
             2018年吻胜品牌诞生，是青鸟消防股份有限公司家用事业部品牌，专注于家用消防领域，后因市场增长需求于2021年正式注册“北京青鸟美好生活科技有限公司”，致力于成为可信赖的家用消防智能硬件服务商，为家居和类家居场景提供具有消防安全功能的物联网智能硬件。基于此，吻胜将不断迭代自有的产品、后台、APP、小程序和服务能力水平，为用户打造一个安全、智能的居住环境，享受科技带来的轻松、舒适和愉悦的生活。
        </p>
         <p class="text_list">
      　吻胜构建“4+N”智能消防报警家居生态，以安全为核心，以吻胜云为中心，搭载包括智能烟感火灾探测报警器、智能燃气探测报警器、智能一氧化碳探测报警器、灭火器等在内的四大自研硬件，实现家居及类家居场景的全屋消防安全智能化，同时利用互联互通的吻胜云开放平台，与合作伙伴分享云平台服务能力，共同打造物联网云生态。
        </p>

        </div>
      </div>

      <div class="culture_brand" id="target">
        <div class="item-title">
          <p>品牌文化</p>
          <span></span>
        </div>
        <div class="center_content">
          <div class="left_content">
            <img src="../../assets/gsjjlogo.png" class="image" />
          </div>
          <div class="right_content">
            <p class="text_list2">
            吻胜，吻指的就是鸱吻，胜指的是厌胜之物。鸱吻，为瑞兽龙之第九子。“海为鱼，虬尾似鸱，用以喷浪则降雨”，鸱吻喜爱在险要处东张西望，也喜欢吞火。鸱吻属厌胜之物，是古代在民俗信仰上的具体体现。由此吻胜代表的是灭火、防范的含义。从另外一个角度吻胜音同稳胜，寓指吻胜品牌定会一往无前，旗开得胜。
            </p>
            <!-- <p class="text_list2">
              鸱吻喜爱在险要处东张西望，也喜欢吞火。鸱吻属厌胜之物，是古代在民俗信仰上的具体体现
            </p>
            <p class="text_list2">
              古人期望借助他们的神力来避火，在房脊上安两个相对的鸱吻，象征辟除火灾。
            </p> -->
          </div>
        </div>
        <!-- <p class="text_list">
          最典型的是北京紫禁城，每一座宫殿上都有防火灾的厌胜之物。
        </p> -->
        <!-- <p class="text_list">
          公司品牌名称取名——吻胜，取龙之九子“鸱吻”镇宅除火之寓意，将品牌与“防患于未然”的理念高度融合，为安全报警，守护家的每一平方。
        </p> -->
        <p class="text_list">

        </p>
      </div>
      <div class="culture_brand" id="honor" style="background-image: url('https://jbufa-wechat.oss-cn-qingdao.aliyuncs.com/web/honor_bg.png');background-repeat: no-repeat;background-size: 100vw 65vw;width: 100vw;height: 65vw;" >
        <div class="item-title" >
          <p>企业荣誉</p>
          <span></span>
        </div>

        <div style="width: 100%;text-align: center;margin-top: 30px">
          <img src="https://jbufa-wechat.oss-cn-qingdao.aliyuncs.com/web/honor.png" style="width: 65vw;height: 50vw" />
        </div>

<!--        <div class="lunbotu">-->
<!--          &lt;!&ndash; <el-carousel-item > &ndash;&gt;-->
<!--          <img src="https://jbufa-wechat.oss-cn-qingdao.aliyuncs.com/web/honor_bg.jpg" class="image" />-->
<!--          &lt;!&ndash; </el-carousel-item> &ndash;&gt;-->
<!--        </div>-->

      </div>


    </div>
  </div>
</template>
<script>
  import Storage from "../../utils/Storage";

  export default {
    name: "aboutUs",
    components: {},
    data() {
      return {
          backgroundDiv: {
            backgroundImage: 'url(' + require('../../assets/us_bg.png') + ')'
        },
        imageBox: [{
            id: 0,
            url: require("../../assets/banner1.jpg")
          },
          {
            id: 1,
            url: require("../../assets/banner2.jpg")
          },
          {
            id: 2,
            url: require("../../assets/banner3.jpg")
          }
          // {
          //   id: 3,
          //   url: require("../../assets/rotationChart/index_bg1.jpg")
          // }
        ],
        select: "",
        showBac: "",
        ifLogin: "",
        isTop: true,
        loginChanges: "登录",
        input: "",
        menu_listones: 5,

      };
    },

    watch: {
      $route(to, from){

        if(to.path == '/aboutUs' && from.path == '/aboutUs' ){
          console.log(to.query)
          if(to.query.id == "target"){
            let toElement = document.getElementById("target");
            toElement.scrollIntoView();
          }else if(to.query.id == "honor"){
            let toElement = document.getElementById("honor");
            toElement.scrollIntoView();
          }
        }
      },
    },


    mounted() {
      window.addEventListener('scroll', this.scrollToTop)
      const that = this
      let ispeed = Math.floor(-that.scrollTop / 5)
      document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
      if(this.$route.query.id == "target"){
        let toElement = document.getElementById("target");
        toElement.scrollIntoView();
      }else if(this.$route.query.id == "honor"){
        let toElement = document.getElementById("honor");
        toElement.scrollIntoView();
      }

    },
    created() {

    },
    methods: {
      scrollToTop() {
        const that = this
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        that.scrollTop = scrollTop
        that.btnFlag = false
      },
      //登录事件
      loginHandler() {
        this.$router.push("/login");
      },

      goBackstage() {
        this.$router.replace("/mainRouter");
      },
      goUrl(url) {
        window.open(url, "_blank");
      }
    },
    destroyed() {
      window.removeEventListener('scroll', this.scrollToTop)
    },
  };
</script>
<style lang="less" scoped>
  @media screen and (min-width: 0px) and (max-width: 1200px) {
    .aboutUs {
      background-color: #fafafa;
      overflow-x: auto;
      min-width: 1000px;
    }
  }

  @media screen and (min-width: 1201px) {
    .aboutUs {
      background-color: #fafafa;
      overflow-x: auto;
      min-width: 1300px;
    }
  }


  @media screen and (min-width: 0px) and (max-width: 1370px) {
    .text_list2{
         font-size: 1.2rem;
      }
      .text_list{
        font-size: 1.2rem;
      }
   .block {
      width: 100%;

      //  margin-top:5rem;
      .lunbotu {
        /deep/.el-carousel__container {
          width: 100%;
          height: 36rem;

        }

        height: 100%;
        width: 100%;
         background-color: #efefef;
        .image {
          width: 100%;
          height: 100%;
        }
      }

      /deep/.el-carousel__button {
        border: 3px solid #f28c02;
        border-radius: 5px 5px;
        padding: 5px 10px;
      }
    }
  }

  @media screen and (min-width: 1371px) {
      .text_list2{
         font-size: 1.5rem;
      }
        .text_list{
        font-size: 1.5rem;
      }
    .block {
      width: 100%;

      // margin-top:5rem;
      .lunbotu {
        /deep/.el-carousel__container {
          width: 100%;
          height: 40.625rem;
        }

        height: 100%;
        width: 100%;
        background-color: #efefef;
        .image {
          width: 100%;
          height: 100%;
        }
      }

      /deep/.el-carousel__button {
        border: 3px solid #f28c02;
        border-radius: 5px 5px;
        padding: 5px 10px;
      }

      // .el-carousel__container {
      //   position: relative;
      //   height: 450 / 16rem;
      // }
    }

  }

  .culture_brand {
    width: 100%;
    background: #ffffff;
    padding-bottom: 5rem;

    .text_list {
      width: 62.5%;
      margin: 0 auto;
      // font-size: 1.5rem;
      line-height: 2.5rem;
       padding: 1.1rem 0 1.1rem 0;
      color: #333333;
      // letter-spacing: 5px;
      // padding: 3rem 0 3rem 0;
    }
  }
  .major_se{
      background:#efefef;
      height: 4rem;
      width: 100%;
  }
  .major_service {
    width: 100%;
    // background: #ffffff;
    padding-bottom: 6rem;

    // padding: 4rem 0;

    .middle_major_show {
      width: 80%;
      height: 14rem;
      // border: 1px solid red;
      margin: 0 auto;
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;

      .middle_major_showchild {
        width: 48%;
        border: 1px solid red;
        background-color: #fff;
        height: 100%;
      }
    }

    .text_list {
      width: 62.5%;
      margin: 0 auto;
      // font-size: 1.5rem;
      line-height: 2.5rem;
      // letter-spacing: 3px;
       padding: 1.1rem 0 1.1rem 0;
      color: #333333;
      // letter-spacing: 5px;
      // padding: 3rem 0 20px 0;
    }
  }

  // 菜单导航样式
  .menuNavigation {
    // margin-top: 41px;
    width: 100%;
    margin-left: 10px;
    height: 80px;
    // padding-left: 20%;
    //  border: 1px rebeccapurple solid;
    background-color: #fff;

    .el-button {
      padding: 1px 1px 1px 10px;
    }

    .imgLogo {
      // border: #12b7f5 1px solid;
    }

    img {
      padding-left: 78%;
      width: 50px;
      height: 50px;
    }

    /deep/.el-input-group__append,
    .el-input-group__prepend {
      background-color: #fff !important;
    }

    .menu_list {
      height: 80px;
      color: #a1a1a1;
      // border: 1px rebeccapurple solid;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .active {
        color: red;
        transition: all 0.8s;
        background: #000;
        color: #fff;
      }
    }

    ul {
      width: 100%;
      padding-left: 3rem;
      text-align: center;
      border-bottom: #dddddd;

      div {
        padding: 0;
        margin: 0;
      }

      li {
        color: #a1a1a1;
        display: inline-block;
        height: 80px;
        line-height: 80px;
        margin: 0 auto;

        vertical-align: middle;
        text-align: center;

        span {
          display: inline-block;
          // padding: 8px 2px 0 2px;
        }

        list-style: none;
        cursor: pointer;

        //transition: font-size 0.5s, font-weight 0.5s;
        //navSelect
        .selected_marker {
          width: 0;
          background: #a1a1a1;
          //text-align: center;
          margin-top: 17px;
          margin-left: 0;
          margin-right: 0;
          height: 3px;
          border: none;
          transition: width 0.2s;
          overflow: hidden;
        }

        .selected_marker_left {
          float: left;
        }

        .selected_marker_right {
          float: right;
        }

        //.navSelect{
        //  font-weight: bold;
        //}
        //margin-bottom: 1px;
        &:hover {
          //background: #128bf533;
          //font-size: 1rem;
          //font-weight: bolder;
          //border-bottom: 2px solid #12b7f5;

          .selected_marker {
            //vertical-align: bottom;
            //text-align: center;
            width: 100%;
          }
        }
      }
    }
  }

  .middle_show {
    width: 80%;
    // border: 1px solid red;
    margin: 0 auto;
    height: auto;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    .middle_showchild {
      width: 20%;
      text-align: center;
      height: 100%;
      border: 1px solid #fff;
      background-color: #fff;

      img {
        width: 70%;
        // border: 1px solid red;
        margin: 0 auto;
        margin-top: 7%;
      }

      b {
        font-size: 1rem;
        color: #000;
      }

      p {
        padding: 15px 0 15px 0;
      }

      .el-button {
        margin-bottom: 20px;
      }
    }
  }

  .titleDisplay {
    background-color: #efefef;
    width: 100%;

    // padding-bottom: 3rem;
    .item-title {
      padding-top: 6rem;
      padding-bottom: 1rem;
      text-align: center;
      color: #333333;
      width: 100%;
      margin: 0 auto;
      font-size: 2.1rem;
      font-weight: 500;

      p {
        text-shadow: 1px 1px 1px #666;
        //  text-decoration: underline;
        // border-bottom: 8px solid #f28c02;
        display: inline-block;
        padding: 0 50px;
        // letter-spacing: 5px;
        font-weight: 550;
      }

      span {
        width: 14%;
        margin: 0 auto;
        height: 8px;
        margin-top: -10px;
        display: block;
        // background: #f28c02;
        background: rgba(242, 140, 2, 0.7);
      }
    }

    .center_content {
      width: 62.5%;
      margin: 0 auto;
      padding-top: 3rem;
      //  border: 1px solid red;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left_content {
        width: 25%;

        .image {
          width: 80%;
        }
      }
     .left_content_us{
        width: 40%;

        .image {
          width: 80%;
        }
     }
      .right_content {
        width: 65%;

        .text_list2 {
             text-align-last:left;//最后一行左对齐
text-align:justify;
text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
          width: 98%;
          margin: 0 auto;
          // font-size: 1.5rem;
          line-height: 2.5rem;
          color: #333333;
          // letter-spacing: 3px;
          padding: 1.5rem 0 10px 0;
        }
      }
    }
    .center_content_us{
        width: 100%;
      margin: 0 auto;
      padding-top: 3rem;
      //  border: 1px solid red;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left_content {
        width: 30%;

        .image {
          width: 80%;
        }
      }
     .left_content_us{
        width: 40%;

        .image {
          width: 80%;
        }
     }
      .right_content {
        width: 65%;

        .text_list2 {
          width: 98%;
          text-align-last:left;
          text-align:justify;
          text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
          margin: 0 auto;
          // font-size: 1.5rem;
          line-height: 2.5rem;
          color: #333333;
          // letter-spacing: 3px;
          padding: 1.5rem 0 10px 0;
        }
      }
    }
    .lest_us{
      width: 62.5%;
      margin: auto;
  background: no-repeat center top;
    background-size: contain;
    .text_list {
      width: 100%;
    //  word-break: normal;
     text-align-last:left;
text-align:justify;
text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
      margin: 0 auto;
      // text-indent:-0.5em;
      // font-size: 1.5rem;
      line-height: 2.5rem;
      // letter-spacing: 3px;
       padding: 1.1rem 0 1.1rem 0;
      color: #333333;
      display: inline-block;
      // letter-spacing: 5px;
      // padding: 3rem 0 3rem 0;
    }
    }
  }
</style>

<template>
  <div class="mainContainer">
    <div class="select">
      <span class="title">举报列表</span>
      <el-button
          type="text"
          size="small"
          icon="el-icon-refresh"
          class="select-button"
          @click="refresh"
      >刷新</el-button>

<!--      <div class="select-input-right-input">-->
<!--        &lt;!&ndash; <el-input placeholder="请输入" v-model="nameinput" size="small" @keyup.13.native="search">-->
<!--          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>-->
<!--        </el-input>&ndash;&gt;-->
<!--        <el-input-->
<!--            placeholder="请输入搜索关键字"-->
<!--            v-model="nameinput"-->
<!--            class="input-with-select"-->
<!--            size="small"-->
<!--            @keyup.13.native="search"-->
<!--            @blur="ifMohuHandler($event)"-->
<!--        >-->
<!--          <el-select-->
<!--              style="width:8rem"-->
<!--              slot="prepend"-->
<!--              v-model="chooseOnlineStatus"-->
<!--              size="small"-->
<!--              @change="upOptionType"-->
<!--              placeholder="请选择"-->
<!--          >-->
<!--            <el-option-->
<!--                v-for="item in optionType"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value"-->
<!--            ></el-option>-->
<!--          </el-select>-->
<!--          <el-button slot="append" icon="el-icon-search" @click="search" size="small"></el-button>-->
<!--        </el-input>-->
<!--      </div>-->
    </div>
    <div class="table-vv">
      <div style="width:100%;overflow:hidden;margin-bottom:0.5rem;">
        <div class="result-info">
          共有
          <span>{{ total }}</span>个举报信息
        </div>
      </div>
      <el-table
          :data="tableData"
          stripe
          size="small"
          class="product-table"
          :header-cell-style="{'background-color': '#eeeeee'}"
      >
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="title" label="举报标题" align="center"></el-table-column>
        <el-table-column prop="content" label="举报内容" align="center"></el-table-column>
        <el-table-column prop="email" label="邮箱" align="center"></el-table-column>
        <el-table-column prop="createTime" label="举报提交时间" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
                type="text"
                size="small"
                icon="el-icon-info"
            >受理</el-button>
            <el-button
                type="text"
                size="small"
                icon="el-icon-info"

            >详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          :total="total"
          layout="sizes,prev, pager, next"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>

  </div>
</template>


<script>
export default {
  name: "reportingCenter",
  data() {
    return {
      productDel: false,
      productAdd: false,
      form: {
        realName: "",
        sysUserId: "",
        typeId: "",
        email: ""
      },
      visible: false,
      total: 0,
      tableData: [],
      optionType: [
        {
          value: "typeName",
          label: "账号所属类型"
        },
        {
          value: "realName",
          label: "真实姓名"
        },
        {
          value: "sysUserName",
          label: "系统账号"
        },

      ], //搜索下拉选择
      chooseOnlineStatus: "真实姓名", //搜索选择
      sysUserlist: [], //账号所属类型
      newSsUserlist: {
        value: "id",
        label: "typeName",
        children: "childs",
        expandTrigger: "hover"
        // emitPath: false
      },
      checked: false,
      //新增账号弹出对话框标记
      dialogVisible: false,
      //修改账号弹出对话框标记
      modifydialogVisible: false,
      //协议类型列表
      protocols: [
        {
          id: 1,
          protocolName: "test"
        }
      ],

      //产品品类列表
      categories: [],

      value: "",
      input: "",
      current: 1,
      pageSize: 10,
      delDialogVisible: false,
      productId: "",
      indexId: "", //反显id
      nameinput: "", //搜索输入
      indexIdlist: "", //反显列表
      formLabelWidth: "120px",
      rules: {
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
          { min: 1, max: 10, message: "长度在 1 到 10 个字符", trigger: "blur" }
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
        sysUserId: [
          { required: true, message: "请选择平台账号", trigger: "change" }
        ],

        typeId: [
          { required: true, message: "请选择所属类型", trigger: "change" }
        ]
      }
    };
  },
  methods: {

    // changex清空
    upOptionType(){
      this.nameinput = ""
    },
    // 根据不同类型搜索
    search() {
      this.current = 1;
      if(this.chooseOnlineStatus == "真实姓名"){
        this.chooseOnlineStatus =  "realName"
      }
      let query = { [this.chooseOnlineStatus]: this.nameinput };

      //查询设备列表
      this.$api
          .accountList({
            page: {
              current: this.current,
              size: this.pageSize
            },
            query: query
          })
          .then(res => {
            this.tableData = res.data.data.records;

            this.total = res.data.data.total;
            let cloudList = JSON.parse(sessionStorage.getItem("cloudList"));
            for (let i = 0; i < cloudList.length; i++) {
              if (
                  this.$store.state.localList.localList.productDel == cloudList[i]
              ) {
                this.productDel = true;
              }
              if (
                  this.$store.state.localList.localList.productAdd == cloudList[i]
              ) {
                this.productAdd = true;
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
    },



    accountList() {
      //查询设备列表
      this.$api
          .reportingList({
            page: {
              current: this.current,
              size: this.pageSize
            }
          })
          .then(res => {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;

          })
          .catch(error => {
            console.log(error);
          });
    },





  },
  created() {
    //查询产品列表
    this.accountList();

  }
};
</script>
<style lang="less" scoped>
@toolBarFontColor: rgba(17, 17, 17, 0.87);

.el-row {
  margin: 1rem 3rem;
  line-height: 0;
  min-width: 900px;

  .platform-toolbar {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;

    .title-info {
      height: 46px;
      line-height: 46px;
      font-weight: bold;
      font-size: 0.8rem;
    }

    .el-col {
      padding: 0 3rem;

      .toolbar-right {
        float: right;
        margin-top: 7px;

        &.export {
          margin-left: 1rem;

          .toolbar-btn {
            color: @toolBarFontColor;
            font-size: 0.9rem;

            &.left {
              margin-left: 1.5rem;
            }

            &.right {
              margin-right: 0;
            }
          }
        }
      }
    }

    .title-info {
      float: left;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .result-info-wrapper {
    height: 1rem;
    margin: 1rem 0 0.5rem 0;
    position: relative;

    .setting-panel {
      position: absolute;
      float: right;
      top: 2rem;
      width: 200px;
      height: 200px;
      background: red;
      z-index: 1000;
      border-radius: 3px;
    }

    .result-info {
      height: 1rem;
      line-height: 1rem;
      font-size: 0.875rem;
      float: left;
      color: #898989;

      span {
        color: #292929;
        padding: 0 0.1rem;
      }
    }

    .data-setting {
      font-size: 0.875rem;
      color: #2673bb;
      float: right;

      i {
        padding-right: 0.2rem;
      }

      cursor: pointer;
    }
  }

  .el-table td,
  .el-table th {
    padding: 0 !important;
  }

  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.product-content {
  height: 6rem;

  .product-info-item {
    float: left;
    margin-right: 4rem;

    .label-info {
      margin-bottom: 0.5rem;

      label {
        font-size: 0.75rem;

        span {
          color: red;
          margin-right: 0.5rem;
        }
      }
    }

    .el-input {
      width: 10rem;
    }

    .el-select {
      width: 13rem;
    }
  }
}
.container-el-row {
  margin-top: 0px;
}
.select {
  height: 46px;
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  padding: 0rem 3rem;
  .title {
    display: inline-block;
    line-height: 46px;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .select-button {
    float: right;
    color: @toolBarFontColor;
    margin-top: 7px;
    margin-left: 15px;
  }
  .select-input-right-input {
    float: right;
    margin-top: 7px;
  }
}
.table-vv {
  padding: 0rem 3rem;
  margin-top: 1rem;
  .result-info {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.875rem;
    float: left;
    color: #898989;

    span {
      color: #292929;
      padding: 0 0.1rem;
    }
  }
  .el-pagination {
    margin: 1rem 0;
    float: right;
  }
}

.item {
  margin: 4px;
  cursor: pointer;
}


.right .el-tooltip__popper {
  padding: 8px 10px;
  cursor: pointer;
}
</style>

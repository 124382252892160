<template>
    <div class="outting">
        <div class="select">
            <span class="title">测试列表(久远)</span>
            <!-- <el-button
        type="text"
        size="small"
        icon="el-icon-refresh"
        class="select-button"
        @click="refresh"
      >刷新</el-button>-->
        </div>
        <div class="table">
            <el-form :model="ruleForm" :rules="rules" style="display:inline-block;;margin-right:1rem"
                @submit.native.prevent ref="ruleForm" size="mini" label-width="80px">
                <el-form-item label="设备MAC" prop="input1">
                    <el-input v-model="ruleForm.input1" ref="input1" @input="inputFn" style="width: 11.25rem;">
                    </el-input>
                </el-form-item>
            </el-form>
            <span style="font-size:14px" class="toolbarTit">信号强度 ：</span>
            <el-input placeholder="" v-model="strength1" type="number" size="mini" style="width:8.25rem;" clearable>
            </el-input>-<el-input placeholder="" v-model="strength2" size="mini" @blur="numberFn" type="number"
                style="width: 8.25rem;;margin-right:1rem" clearable></el-input>
            <!-- </div> -->
            <!-- </div> -->
            <div style="float:right;margin-bottom:.5rem;">
                <el-button type="primary" round size="small" @click="confirmChoose" style="margin-right: 50px">批量通过
                </el-button>
                <!--        <el-button type="primary" round size="small" @click="startTest"  >批量不通过</el-button>-->


                <el-button type="primary" round size="small" @click="startTest" v-show="!ifTest">开始测试</el-button>
                <el-button round size="small" @click="endTest" v-show="ifTest" type="danger">结束测试</el-button>
            </div>
            <el-table :data="tableData" style="width:100%" stripe size="small" fixed height="30rem"
                :header-cell-style="{'background-color': '#eeeeee'}" :cell-style="cellStyle" @select="getSelected"
                ref="singleTable">
                <el-table-column type="selection" width="50" align="center"></el-table-column>
                <el-table-column type="index" width="50" label="序号" align="center" fixed> </el-table-column>
                <el-table-column prop="mac" label="mac" min-width="1" fixed align="center">
                    <template slot-scope="{row}">{{ row.mac || '暂无数据' }}</template>
                </el-table-column>

                <el-table-column prop="statusTime" label="最新上报时间" min-width="1" fixed align="center">
                    <template slot-scope="{row}">{{ row.statusTime || '暂无数据' }}</template>
                </el-table-column>

                <el-table-column prop="wirelessModuleElectricity" label="电量" min-width="1" fixed align="center"
                    :formatter="stateFormatLightAlarm">
                    <!-- <template slot-scope="{row}">{{ row.wirelessModuleElectricity || '暂无数据' }}</template> -->
                </el-table-column>
                <el-table-column prop="productName" label="产品型号" min-width="1" fixed align="center">
                    <template slot-scope="{row}">{{ row.productName || '暂无数据' }}</template>
                </el-table-column>
                <el-table-column prop="rssi" label="信号强度" min-width="1" fixed align="center">
                    <!-- <template slot-scope="scope"> -->
                    <!-- <span v-if="strength1!= '' <(scope.row.rssi)&& (scope.row.rssi)<strength2!= '' "
                            style="color:#333">{{scope.row.rssi}}</span>
                        <span v-else-if="strength1 == '' " style="color:#333">{{scope.row.rssi}}</span>
                        <span v-else-if="strength2 == '' &&strength1 != '' "
                            style="color:#333">{{scope.row.rssi}}</span>
                        <span v-else style="color:red">{{scope.row.rssi}}</span> -->
                    <!-- </template> -->
                    <template slot-scope="{row}">{{ row.rssi || '暂无数据' }}</template>
                </el-table-column>
                <!--  -->
                <el-table-column prop="selfChecking" label="自检" min-width="1" fixed align="center"
                    :formatter="stateFormatSelfChecking"></el-table-column>
                <el-table-column prop="fireAlarm" label="报警" min-width="1" fixed align="center"
                    :formatter="stateFormatFireAlarm"></el-table-column>
                <el-table-column prop="fireAlarmFalse" label="报警恢复" min-width="1" fixed align="center"
                    :formatter="stateFireAlarmFalse">
                </el-table-column>
                <el-table-column prop="detectorLossFault" label="故障" min-width="1" fixed align="center"
                    :formatter="stateFormatDetectorLossFault">
                    <!-- <template slot-scope="{row}">{{ row.detectorLossFault || '暂无数据' }}</template> -->
                </el-table-column>
                <el-table-column prop="detectorLossFaultFalse" label="故障恢复" min-width="1" fixed align="center"
                    :formatter="stateDetectorLossFaultFalse">
                    <!-- <template slot-scope="{row}">{{ row.detectorLossFaultFalse || '暂无数据' }}</template> -->
                </el-table-column>
                <el-table-column prop label="当前状态" min-width="1" fixed align="center" :formatter="stateFormatResult">
                </el-table-column>
                <el-table-column prop="id" label="操作" min-width="3" fixed align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" round size="mini" @click="ifHege(scope.$index, scope.row)">合格
                        </el-button>
                        <el-button round size="mini" @click="ifNotHege(scope.$index, scope.row)">不合格</el-button>
                    </template>
                </el-table-column>
            </el-table>

        </div>
    </div>
</template>
<script>
    import bus from "../../components/bus";
    import Storage from "../../utils/Storage";
    export default {
        name: "TestDevice",
        data() {
            let checkMac1 = (rule, value, cb) => {
                const ifMac = /^86\d{13}$/;
                value = value.trim()
                if (value) {
                    if (ifMac.test(value) == true) {

                        if (this.checkRepeat(cb, 0, value)) {
                            if (this.tableData != null) {

                                if (this.tableData.length < 200) {
                                    for (let i = 0; i < this.tableData.length; i++) {
                                        if (value == this.tableData[i].mac) {
                                            this.$message({
                                                type: 'info',
                                                message: '请重新上传，不可重复'
                                            });
                                            return
                                        }
                                    }
                                }
                            } else {
                                this.tableData = []
                            }
                            this.$api.checkJyMacHandler(value).then(res => {
                                this.ifRegister = res.data.data;
                                if (res.data.data == true) {
                                    cb();
                                    this.ifPass.ifPass1 = true;
                                    this.$refs.input1.blur();
                                    this.tableData.push({
                                        mac: value,
                                        result: 2,
                                    })
                                    this.tableData = this.unique(this.tableData);
                                    this.ruleForm.input1 = ""
                                    // console.log(this.tableData)
                                    this.$refs.input1.focus();
                                } else {
                                    this.ifPass.ifPass1 = false;
                                    cb(new Error("该设备未注册!"));
                                }
                            });


                            //    console.log(this.tableData)
                        }


                    } else {
                        this.ifPass.ifPass1 = false;
                        cb(new Error("请输入正确的MAC号！"));
                    }
                } else {
                    cb();
                    this.ifPass.ifPass1 = true;
                    // this.$refs.input1.blur();
                    this.$refs.input1.focus();
                }
            };
            return {
                ifTest: false,
                lockReconnect: false,
                timer: null,
                ifJeep: false,
                ifTotalPass: true,
                ifPass: {
                    ifPass1: true,

                },
                ifRegister: "",
                isDestory: false,
                tableData: [],
                allIds: [],
                productId: "",
                netId: "",
                websock: null,
                ifshauxin: false,
                input1: "",
                strength1: "",
                strength2: "",
                ifMac: /^86\d{13}$/,
                srt: "IMEI:",
                ruleForm: {
                    input1: "",
                },
                rules: {
                    input1: [
                        // { required: true, message: "请输入要测试的设备！" },
                        {
                            validator: checkMac1,
                            trigger: "change"
                        }
                    ],
                },
                ifTestClick: false,
                obj: {}
            };
        },
        methods: {
            unique(arr) {
                const res = new Map();
                return arr.filter((arr) => !res.has(arr.mac) && res.set(arr.mac, 1));
            },
            // 对比强度值的大小

            numberFn() {
                let result = ""
                if (!this.strength1 || !this.strength2) {
                    this.result = '输入的数不能为空'
                } else {
                    result = parseInt(this.strength1) == parseInt(this.strength2) ? '两个数相等' : parseInt(this.strength1) >
                        parseInt(this.strength2) ? '数据1大于数据2' : '数据2大于数据1'
                    if (parseInt(this.strength1) == parseInt(this.strength2) || parseInt(this.strength1) > parseInt(this
                            .strength2)) {
                        // console.log("chonhxin")
                        this.strength2 = ""
                        this.$message({
                            type: 'warning',
                            message: '信号强度值设置不合法'
                        });
                    }
                }
                // console.log(result)
            },
            inputFn(val) {
                // console.log(val)
                if (val.length >= 15) {
                    if (val.indexOf(":") != -1) {
                        console.log("触发");
                        this.ruleForm.input1 = val.split("IMEI:")[1];
                    }
                }
            },
            checkRepeat(cb, index, value) {
                let arrValue = Object.values(this.ruleForm);
                arrValue.forEach(function (item, index) {
                    arrValue[index] = item.trim();
                })
                arrValue.splice(index, 1)
                if (arrValue.indexOf(value) != -1) {
                    switch (index) {
                        case 0:
                            this.ifPass.ifPass1 = false;
                            break;
                    }
                    // Vue.set(this.ifPass, name, value)
                    cb(new Error("MAC号重复"));
                    return false;
                } else {
                    return true;
                }
            },
            getSelected(selection, row) {
                let macArr = [];
                for (let i = 0; i < selection.length; i++) {
                    macArr.push(selection[i].mac);
                }
                let idArr = [];
                for (let i = 0; i < selection.length; i++) {
                    idArr.push(selection[i].id);
                }
            },

            allPass() {
                let passMacList = [];
                for (let i = 0; i < this.tableData.length; i++) {
                    // console.log(this.tableData[i])
                    if (this.tableData[i].result == 2) {
                        let passData = {
                            mac: this.tableData[i].mac,
                            message: JSON.stringify(this.tableData[i]),
                            testTime: this.tableData[i].statusTime,
                        }
                        passMacList.push(passData);
                    }
                }
                this.$api
                    .ifPassHandler({
                        deviceQcRecordDtos: passMacList,
                        isPass: 1
                    })
                    .then(res => {
                        if (res.data.code == 200) {
                            // console.log(res.data.message)
                            for (let i in passMacList) {
                                for (let j in this.tableData) {
                                    if (passMacList[i].mac == this.tableData[j].mac) {
                                        this.tableData[j].result = 1;
                                    }
                                }
                            }
                        }
                    });
            },
            confirmChoose() {
                this.$confirm('确认批量通过吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.allPass()
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            cellStyle(row, column, rowIndex, columnIndex) {
                // if(row.column.label=="丟失"&&row.row.detectorLossFault=="false"){
                //    return "color:#F56C6C";
                // }
                // if(row.column.label=="丟失"&&row.row.detectorLossFault=="true"){
                //    return "color:#67C23A";
                // } && row.rssi == "true"
                if (row.column.label == "信号强度") {
                    if (this.strength1 != " " && this.strength2 != "") {
                        // console.log("强度值" + this.strength1, this.strength2, row.row.rssi)
                        if (parseInt(this.strength1) < parseInt(row.row.rssi) && parseInt(row.row.rssi) < parseInt(this
                                .strength2)) {
                            console.log("变色小")
                            return "color:#333";
                        } else {
                            console.log("变色大")
                            return "color:red";
                        }
                    }

                }
                if (row.column.label == "报警" && row.row.fireAlarm == "false") {
                    return "color:#F56C6C";
                }
                if (row.column.label == "报警" && row.row.fireAlarm == "true") {
                    return "color:#67C23A";
                }
                if (row.column.label == "报警恢复" && row.row.fireAlarmFalse == "false") {
                    return "color:#F56C6C";
                }
                if (row.column.label == "报警恢复" && row.row.fireAlarmFalse == "true") {
                    return "color:#67C23A";
                }
                if (row.column.label == "自检" && row.row.selfChecking == "false") {
                    return "color:#F56C6C";
                }
                if (row.column.label == "自检" && row.row.selfChecking == "true") {
                    return "color:#67C23A";
                }
                if (row.column.label == "故障" && row.row.detectorLossFault == "false") {
                    return "color:#F56C6C";
                }
                if (row.column.label == "故障" && row.row.detectorLossFault == "true") {
                    return "color:#67C23A";
                }
                if (row.column.label == "当前状态" && row.row.result == "0") {
                    return "color:#F56C6C";
                }
                if (row.column.label == "当前状态" && row.row.result == "1") {
                    return "color:#67C23A";
                }
                if (row.column.label == "故障恢复" && row.row.detectorLossFaultFalse == "false") {
                    return "color:#F56C6C";
                }
                if (row.column.label == "故障恢复" && row.row.detectorLossFaultFalse == "true") {
                    return "color:#67C23A";
                }
                if (row.column.label == "测试状态" && row.row.result == "1") {
                    return "color:#67C23A";
                }
                if (row.column.label == "测试状态" && row.row.result == "0") {
                    return "color:#F56C6C";
                }
                if (row.column.label == "丟失" && row.row.detectorLossFault == "false") {
                    return "color:#F56C6C";
                }
                if (row.column.label == "丟失" && row.row.detectorLossFault == "true") {
                    return "color:#67C23A";
                }
            },
            //故障渲染函数
            stateFormatDetectorLossFault(row) {
                if (row.detectorLossFault == "true") {
                    return "通过";
                } else if (row.detectorLossFault == "false") {
                    return "未检出";
                } else {
                    return "未检出";
                }
            },
            // 故障恢复渲染函数
            stateDetectorLossFaultFalse(row) {
                if (row.detectorLossFaultFalse == "true") {
                    return "通过";
                } else if (row.detectorLossFaultFalse == "false") {
                    return "未检出";
                } else {
                    return "未检出";
                }
            },
            //自检渲染函数
            stateFormatSelfChecking(row) {
                if (row.selfChecking == "true") {
                    return "通过";
                } else if (row.selfChecking == "false") {
                    return "未检出";
                } else {
                    return "未检出";
                }
            },
            //火警渲染函数
            stateFormatFireAlarm(row) {
                if (row.fireAlarm == "true") {
                    return "通过";
                } else if (row.fireAlarm == "false") {
                    return "未检出";
                } else {
                    return "未检出";
                }
            },
            // 报警恢复
            stateFireAlarmFalse(row) {
                if (row.fireAlarmFalse == "true") {
                    return "通过";
                } else if (row.fireAlarmFalse == "false") {
                    return "未检出";
                } else {
                    return "未检出";
                }
            },
            //电池电量比渲染函数
            stateFormatLightAlarm(row) {
                if (row.wirelessModuleElectricity) {
                    return row.wirelessModuleElectricity + '%';
                } else {
                    return "暂无数据";
                }
            },
            //测试结果渲染函数
            stateFormatResult(row) {
                if (row.result == 0) {
                    return "不通过";
                } else if (row.result == 1) {
                    return "通过";
                } else {
                    return "测试中";
                }
            },
            //合格请求函数
            ifHege(index, row) {
                this.$api
                    .ifPassHandler({
                        deviceQcRecordDtos: [{
                            mac: row.mac,
                            message: JSON.stringify(row),
                            testTime: row.statusTime
                        }],
                        isPass: 1
                    })
                    .then(res => {
                        // console.log(res.data);
                        if (res.data.code == 200) {
                            for (let i in this.tableData) {
                                if (this.tableData[i].mac == row.mac) {
                                    this.tableData[i].result = 1;
                                }
                            }
                        }
                    });
            },
            //不合格请求函数
            ifNotHege(index, row) {
                this.$api
                    .ifPassHandler({
                        deviceQcRecordDtos: [{
                            mac: row.mac,
                            message: JSON.stringify(row),
                            testTime: row.statusTime
                        }],
                        isPass: 0
                    })
                    .then(res => {
                        if (res.data.code == 200) {
                            for (let i in this.tableData) {
                                if (this.tableData[i].mac == row.mac) {
                                    this.tableData[i].result = 0;
                                }
                            }
                        }
                    });
            },
            startTest() {
                this.ifTestClick = true;
                sessionStorage.setItem("ifTestClick", true);
                this.ifTest = true;
                this.initWebSocket();
            },
            endTest() {
                this.ifTest = false;
                this.websocketclose();
            },
            //跳转页面请求函数
            firstQrcode() {
                this.$api
                    .FirstQrcodeHandler({
                        macs: this.allIds,
                        netOperatorId: this.netId,
                        productId: this.productId,
                        testStatus: 0
                    })
                    .then(res => {
                        // console.log(res.data);
                    });
            },
            //初始化websocket
            initWebSocket() {
                const wsuri = "wss://jiayong360safe.cn/iotQc/socket/" + Storage.getItem("token"); //正式
                // const wsuri = "ws://47.105.137.41:9081/iotQc/socket/" + Storage.getItem("token");
                this.websock = new WebSocket(wsuri);
                this.websock.onmessage = this.websocketonmessage;
                this.websock.onopen = this.websocketonopen;
                this.websock.onerror = this.websocketonerror;
                this.websock.onclose = this.websocketclose;
            },
            websocketonmessage(e) {
                this.heartCheck.reset();
                const res = JSON.parse(e.data);
                console.log(res)
                if (res.type == 14) {
                    for (let i in this.tableData) {
                        if (this.tableData[i].mac == res.body.mac) {
                            this.tableData.splice(i, 1, res.body);
                            // console.log(this.tableData)
                        }
                        // if(this.tableData[i].mac ==res.body.mac&& this.tableData[i].result == 2  ){
                        //      this.tableData[i] =
                        // }
                    }

                }
            },
            websocketonopen() {
                //连接建立之后执行send方法发送数据
                this.heartCheck.start();
            },
            websocketonerror() {
                //连接建立失败重连
                this.reconnect();
            },
            websocketclose() {
                if (this.isDestory) {
                    return; //已经关闭页面
                } else if (this.ifTest) {
                    //还在测试中，重连websocket
                    this.reconnect();
                } else {
                    //点击结束测试，可以结束websocket连接
                    clearTimeout(this.timeout);
                    clearTimeout(this.serverTimeoutObj);
                    this.websock.close(); //离开路由之后断开websocket连接
                }
            },
            websocketsend(Data) {
                //数据发送
                this.websock.send(Data);
            },
            //重连函数
            reconnect() {
                if (this.lockReconnect) return;
                this.lockReconnect = true;
                let that = this;
                //没连接上会一直重连，设置延迟避免请求过多
                setTimeout(function () {
                    that.initWebSocket();
                    that.lockReconnect = false;
                }, 10000);
            },
            //刷新token函数
            refreshToken() {
                let subDate = {
                    grant_type: "refresh_token",
                    client_id: "test-client",
                    client_secret: "test-secret",
                    refresh_token: Storage.getItem("refresh_token")
                };
                let subDateStr = qs.stringify(subDate);
                this.$api
                    .getLogin(subDateStr)
                    .then(res => {
                        if (res.status === 200) {
                            Storage.setItem("token", res.data.access_token);
                            Storage.setItem("refresh_token", res.data.refresh_token);
                            this.refreshTime = res.data.expires_in;
                            let refreshTime = this.refreshTime / 3 - 10;
                            console.log("刷新token成功");
                            this.timer = setInterval(() => {
                                this.refreshTokenTimer();
                            }, refreshTime * 1000);
                        }
                    })
                    .catch(error => {});
            },
            refreshTokenTimer() {
                let subDate = {
                    grant_type: "refresh_token",
                    client_id: "test-client",
                    client_secret: "test-secret",
                    refresh_token: Storage.getItem("refresh_token")
                };
                let subDateStr = qs.stringify(subDate);
                this.$api
                    .getLogin(subDateStr)
                    .then(res => {
                        if (res.status === 200) {
                            Storage.setItem("token", res.data.access_token);
                            Storage.setItem("refresh_token", res.data.refresh_token);
                            this.refreshTime = res.data.expires_in;
                        }
                    })
                    .catch(error => {});
            }
        },
        mounted() {
            this.$refs.input1.focus();
        },
        //页面销毁之前，关闭定时器和webSocket
        beforeDestroy() {
            if (this.ifTest) {
                clearInterval(this.timer);
                this.timer = null;
                this.isDestory = true;
                clearTimeout(this.timeout);
                clearTimeout(this.serverTimeoutObj);
                if (this.websock) {
                    this.websock.close(); //离开路由之后断开websocket连接
                }
                this.heartCheck = null;
                sessionStorage.setItem("ifTestClick", false);
            } else {
                return;
            }
        },
        created() {
            if (sessionStorage.getItem("obj")) {
                let macs = JSON.parse(sessionStorage.getItem("obj")).macs;
                this.obj = JSON.parse(sessionStorage.getItem("obj"));
            }

            if (sessionStorage.getItem("ifshuaxin") == "true") {
                if (sessionStorage.getItem("ifTestClick") == "true") {
                    let obj = this.obj;
                    obj.testStatus = 1;

                    this.$api.refrashTestResultHandler(obj).then(res => {
                        this.tableData = res.data.data;
                    });
                } else {
                    let obj = this.obj;
                    obj.testStatus = 0;
                    this.$api.refrashTestResultHandler(this.obj).then(res => {
                        this.tableData = res.data.data;
                    });
                }
            } else {
                if (sessionStorage.getItem("obj")) {
                    let obj = JSON.parse(sessionStorage.getItem("obj"));
                    this.$api.FirstQrcodeHandler(obj).then(res => {
                        // console.log(res.data.data);
                        this.tableData = res.data.data;
                    });
                }
            }

            //心跳控制
            let that = this;
            this.heartCheck = {
                timeout: 10000,
                timeoutObj: null,
                serverTimeoutObj: null,
                start: function () {
                    let self = this;
                    this.timeoutObj && clearTimeout(this.timeoutObj);
                    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
                    this.timeoutObj = setTimeout(function () {
                        if (that.isDestory) {} else {
                            that.websocketsend("p");
                            console.log("p");
                        }
                        self.serverTimeoutObj = setTimeout(function () {
                            that.websock.close();
                        }, self.timeout);
                    }, this.timeout);
                },
                reset: function () {
                    clearTimeout(this.timeout);
                    clearTimeout(this.serverTimeoutObj);
                    this.start();
                }
            };
        },
        beforeRouteEnter(to, from, next) {
            console.log(from.path);
            if (from.path == "/") {
                sessionStorage.setItem("ifshuaxin", true);
                next(vm => {});
            } else {
                sessionStorage.setItem("ifshuaxin", false);
                next(vm => {});
            }

            next(vm => {});
        },
        beforeRouteLeave(to, from, next) {
            // console.log(to, from);
            if (this.obj.macs) {
                if (this.obj.macs.length !== 0) {
                    this.$confirm("离开本页面时本页面现有数据会清空，确定离开吗?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        sessionStorage.removeItem("obj");
                        next();
                    });
                }
            } else {
                sessionStorage.removeItem("obj");
                next();
            }
        }
    };
</script>
<style lang="less" scoped>
    @toolBarFontColor: rgba(17, 17, 17, 0.87);
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .select {
            height: 46px;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .select-button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
                margin-left: 15 /16rem;
            }
        }

        .table {
            padding: 0rem 3rem;
            margin-top: 1rem;
        }
    }
</style>

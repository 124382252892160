import { render, staticRenderFns } from "./activityPrizes.vue?vue&type=template&id=77a0e500&scoped=true&"
import script from "./activityPrizes.vue?vue&type=script&lang=js&"
export * from "./activityPrizes.vue?vue&type=script&lang=js&"
import style0 from "./activityPrizes.vue?vue&type=style&index=0&id=77a0e500&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77a0e500",
  null
  
)

export default component.exports
<template>
  <div>
    <div v-for="item in list"  style="margin-top: 20px">
        <div style="width: 56vw;background-color: white;border-radius: 10px;height:16vw;margin: 0 auto;display: flex;align-items: center;padding-right: 20px;
          min-height: 320px;min-width: 1100px" >

           <img :src="item.newsImgUrl" style="width: 30vw;height: 16vw;border-radius: 10px;min-height: 320px;min-width: 600px">

           <div style="flex: 1;margin-left: 30px;color: black">
             <div style="font-weight: bold;font-size: 25px;display: flex;align-items: center;">
               <div style="width: 5px;height: 25px;background-color: #fba417;display: inline-block;vertical-align: middle"></div>
               <div style="display: inline-block;vertical-align: middle;margin-left: 10px">{{item.newsTitle}}</div>
             </div>
             <div style="margin-top: 20px;margin-left: 10px">项目位置：{{item.position}}</div>
             <div style="margin-top: 10px;margin-left: 10px">应用系统：{{item.applicationSystem}}</div>
             <div style="margin-top: 10px;margin-left: 10px;text-align:justify;min-width: 400px">项目亮点：{{item.newsContent.replace('<\p\>','').replace('<\/\p\>','').replace('&lt;br/&gt','')}}</div>
           </div>
        </div>
    </div>
    <div style="float:right;margin-right: 20vw;margin-top: 50px;margin-bottom: 50px">
      <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          class="pagin"
          :page-size="pageSize"
          @current-change="handlePageChange"

      ></el-pagination>
<!--      <el-pagination-->
<!--          small-->
<!--          layout="prev, pager, next"-->
<!--          prev-text="上一页"-->
<!--          next-text="下一页"-->
<!--          background="false"-->
<!--          :page-size="pageSize"-->
<!--          @current-change="handlePageChange"-->
<!--          :total="total">-->
<!--      </el-pagination>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "applicationcases",
  data() {
    return {
      list:[],
      total:0,
      current: 1,
      pageSize:3
    }
  },
  created() {
    this.getAppCasesList()
  },
  methods: {
    handlePageChange(val) {

      this.current = val;
      this.getAppCasesList();
    },

    getAppCasesList(){
      this.$api
          .appCasesFirstList({

              current: this.current,
              size: this.pageSize

          })
          .then(res => {
            this.list = res.data.data.records;
            this.total = res.data.data.total;

          })
          .catch(error => {
            console.log(error);
          });
    }

  }

}

</script>

<style scoped>

</style>

<template>
    <div class="outting">
        <div class="select">
            <span class="title">奖品列表</span>
            <el-button type="text" size="small" icon="el-icon-plus" @click="addUserHandler" class="addRoleButton">新建奖品
            </el-button>
            <!-- <div class="select-input-right-input"> -->
            <el-input style="width:200px" v-model="prizeName" class="inputlist" prefix-icon="el-icon-search"
                placeholder="请输入奖品名称" size="small" @keyup.enter.native="appList"></el-input>
            <!-- </div> -->
        </div>
        <div class="table-vv">
            <el-table :data="tableData" style="width: 100%" stripe size="small"
                :header-cell-style="{'background-color': '#eeeeee'}">
                <el-table-column prop="prizeName" label="奖品名称 " min-width="2" fixed align="center">
                </el-table-column>
                <el-table-column prop="prizeType" label="奖品类型" min-width="2" fixed align="center">
                    <template
                        slot-scope="{row}">{{ row.prizeType  == 0?'实物': row.prizeType  == 1?'补签卡':'优惠券' }}</template>
                </el-table-column>
                <el-table-column prop="prizePicture" label="奖品图片地址" min-width="2" fixed align="center">
                    <template slot-scope="scope">
                        <el-popover placement="top-start" title trigger="hover">
                            <el-image :src="scope.row.prizePicture" :fit="fit" style="width:160px;height:160px"
                                referrerpolicy="no-referrer"></el-image>
                            <el-image slot="reference" :src="scope.row.prizePicture" style="width: 50px;height: 50px"
                                :fit="fit" referrerpolicy="no-referrer"></el-image>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="prizeStock" label="奖品库存" min-width="2" fixed align="center">
                    <template slot-scope="scope">
                        <el-input style="width:5rem" type="text" v-model="scope.row.prizeStock"
                            v-show="scope.row.iseditor" size="mini"> </el-input>
                        <span v-show="!scope.row.iseditor">{{scope.row.prizeStock}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="surplusStock" label="剩余库存" min-width="2" fixed align="center"></el-table-column>
              <el-table-column prop="goodsName" label="关联商品" min-width="2" fixed align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="2" fixed align="center"></el-table-column>
                <el-table-column min-width="1" label="操作" width="180" align="center">
                    <template slot-scope="scope">

                        <el-button @click="save(scope.row)" v-show="scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">修改
                        </el-button>
                        <el-button @click="edit(scope.row,scope)" v-show="!scope.row.iseditor" type="text" size="small"
                            icon="el-icon-info">编辑
                        </el-button>
                        <el-popconfirm title="确定删除这个奖品吗？" @onConfirm="deleteAddHandler()">
                            <el-button type="text" size="small" icon="el-icon-delete"
                                @click="handleDelete(scope.$index, scope.row)" style="margin-left:10px;"
                                slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="total" class="pagin"
                @current-change="changeCurrent"></el-pagination>
        </div>
        <el-dialog title="新增奖品" :visible.sync="addDialogFormVisible" :close-on-click-modal="false"
                   width="80%">
            <el-form :model="addForm" size="small"  :rules="addRules" ref="addRules"
                enctype="multipart/form-data">
                <el-form-item label="奖品名称" :label-width="formLabelWidth" prop="prizeName">
                    <el-input v-model="addForm.prizeName" autocomplete="off" style="width:315px">
                    </el-input>
                </el-form-item>
                <el-form-item label="奖品类型" :label-width="formLabelWidth" prop="prizeType">
                    <el-select v-model="addForm.prizeType" placeholder="请选奖品类型">
                        <el-option label="实物" value="0"></el-option>
                        <el-option label="补签卡" value="1"></el-option>
                        <el-option label="优惠券" value="2"></el-option>
                        <el-option label="优惠券(公众号推广)" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="奖品库存" :label-width="formLabelWidth" prop="prizeStock">
                    <el-input v-model="addForm.prizeStock"
                        onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" style="width:315px">
                    </el-input>
                </el-form-item>
                <el-form-item label="商品列表图片" :label-width="formLabelWidth" ref="formImg" prop="prizePicture">
                    <el-upload action="#" :on-change="handleChange" list-type="picture-card" v-show="!value"
                        :show-file-list="false">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt />
                    </el-dialog>
                    <div v-show="value" class="el-upload-list el-upload-list--picture-card">
                        <div class="el-upload-list__item is-success">
                            <img class="avatar" v-show="value" :src="value" />
                            <label class="el-upload-list__item-status-label">
                                <i class="el-icon-upload-success el-icon-check"></i>
                            </label>
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview">
                                    <i class="el-icon-zoom-in" @click.stop="handlePictureCardPreview"></i>
                                </span>
                                <span class="el-upload-list__item-delete">
                                    <i class="el-icon-delete" @click.stop="handleRemove"></i>
                                </span>
                            </span>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item label="商品详情" prop="details"  :label-width="formLabelWidth" style="height: 500px;width: 80%">
                  <editor-bar :uri='uri' v-model="addForm.details" :isClear="isClear" @change="change">
                  </editor-bar>
                </el-form-item>
                <el-form-item v-if="addForm.prizeType == 2 || addForm.prizeType == 3" label="优惠券折扣类型" :label-width="formLabelWidth"
                    prop="couponDiscountType">
                    <el-select v-model="addForm.couponDiscountType " placeholder="请选奖品类型">
                        <el-option label="无门槛" value="0"></el-option>
                        <el-option label="满减" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="addForm.prizeType == 2 ||addForm.prizeType == 3" label="优惠券满足金额" :label-width="formLabelWidth"
                    prop="couponFullPrice">
                    <el-input v-model="addForm.couponFullPrice" autocomplete="off" style="width:315px">
                    </el-input>
                </el-form-item>
                <el-form-item v-if="addForm.prizeType == 2 ||addForm.prizeType == 3" label="优惠券减少金额" :label-width="formLabelWidth"
                    prop="couponMinusPrice">
                    <el-input v-model="addForm.couponMinusPrice" autocomplete="off" style="width:315px">
                    </el-input>
                </el-form-item>
                <el-form-item v-if="addForm.prizeType == 3" label="优惠券关联商品" :label-width="formLabelWidth"
                              prop="couponMinusPrice" >


                   {{name}}
                  <i class="el-icon-plus" style="color: #f28c02" @click="chooseGoods"></i>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addAppListForm('addForm')" size="small">确 定</el-button>
            </div>
        </el-dialog>
      <el-dialog title="修改奖品" :visible.sync="editDialog" :close-on-click-modal="false">
        <el-form :model="editForm" size="small" style="50%" :rules="addRules" ref="addRules"
                 enctype="multipart/form-data">

          <el-form-item label="奖品库存" :label-width="formLabelWidth" prop="prizeStock">
            <el-input v-model="editForm.prizeStock"
                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" style="width:315px">
            </el-input>
          </el-form-item>
          <el-form-item label="奖品图片" :label-width="formLabelWidth" ref="formImg" prop="prizePicture">
            <el-upload action="#" :on-change="handleChange" list-type="picture-card" v-show="!value"
                       :show-file-list="false">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt />
            </el-dialog>
            <div v-show="value" class="el-upload-list el-upload-list--picture-card">
              <div class="el-upload-list__item is-success">
                <img class="avatar" v-show="value" :src="value" />
                <label class="el-upload-list__item-status-label">
                  <i class="el-icon-upload-success el-icon-check"></i>
                </label>
                <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview">
                                    <i class="el-icon-zoom-in" @click.stop="handlePictureCardPreview"></i>
                                </span>
                                <span class="el-upload-list__item-delete">
                                    <i class="el-icon-delete" @click.stop="handleRemove"></i>
                                </span>
                            </span>
              </div>
            </div>
          </el-form-item>


        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editDialog = false" size="small">取 消</el-button>
          <el-button type="primary" @click="save()" size="small">确 定</el-button>
        </div>
      </el-dialog>

    </div>
</template>
<script>
    import EditorBar from "../../../components/appWangEnduit";
    let flag = false;
    let name = "";
    let id = "";
    export default {
        name: "prizeList",
        components: {
          EditorBar
        },
        data() {
            var valiIcon = (rule, value, callback) => {
                if (!this.value) {
                    callback(new Error('请上传图片'));
                } else {
                    callback();
                }
            };
            return {
                name:'',
                id:'',
                uri: "",
                isClear: false,
                editDialog: false,
                dialogVisible: false,
                dialogImageUrl: "",
                current: 1,
                iseditor: false,
                size: 10,
                fit: "fill",
                total: 0,
                value: "",
                tableData: [],
                file: {},
                deleteId: "",
                editId: "",
                addDialogFormVisible: false,
                // 限制开始时间
                pickerOptionsStart: {},
                pickerOptionsEnd: {},
                prizeName: '',
                addForm: {
                    prizeType: "",
                    prizeName: "",
                    prizePicture: "",
                    prizeStock: "",
                    couponDiscountType: "",
                    couponFullPrice: "",
                    couponMinusPrice: "",
                    details: '',
                },
                editForm:{
                  prizePicture: "",
                  prizeStock: "",
                  id:""
                },
                addRules: {
                    prizeName: [{
                            required: true,
                            message: "请输入奖品名称",
                            trigger: "blur"
                        },
                        {
                            min: 0,
                            max: 10,
                            message: '长度在6个字符以内',
                            trigger: 'blur'
                        }
                    ],
                    prizeType: [{
                        required: true,
                        message: "选择活动类型",
                        trigger: "change"
                    }],
                    prizeStock: [{
                            required: true,
                            message: "请输入奖品库存",
                            trigger: "blur"
                        },
                        // {
                        //     type: 'number',
                        //     required: true,
                        //     message: "请填写数字",

                        // }
                    ],
                    prizePicture: [{
                        required: false,
                        validator: valiIcon,
                        trigger: 'change'
                    }], // 图片验证,
                    couponDiscountType: [{
                        required: false,
                        message: "请输入奖品类型",
                        trigger: "blur"
                    }],
                    couponFullPrice: [{
                        required: false,
                        message: "请输入优惠券满足金额",
                        trigger: "blur"
                    }],
                    couponMinusPrice: [{
                        required: false,
                        message: "请输入优惠券减少金额",
                        trigger: "blur"
                    }],
                },
                formLabelWidth: "180px",
            };
        },
        created() {
            this.appList();
        },
      beforeRouteEnter(to, from, next) {
        if(from.path == "/chooseGoods"){
          name = from.params.name
          id = from.params.id
          flag = true;
        }else{
         flag = false;
         name = "";
         id = ""
        }
        next();
      },
      activated() {
          if(flag == true){
            this.addDialogFormVisible  = true
            this.id = id;
            this.name = name

          }

      },
      methods: {
        change(val) {

        },
          chooseGoods(data) {

            this.$router.push('/chooseGoods',);
            this.addDialogFormVisible = false;
          },
            edit(row, index) {
              this.value = "";
                this.file = {}
                this.editDialog = true
                this.editForm.id = row.id
                this.editForm.prizeStock = row.prizeStock
            },
            save() {

              let formData = new FormData();
              formData.append("prizeStock", this.editForm.prizeStock);
              formData.append("id", this.editForm.id);
              if(this.file != null && this.file != {}){
                formData.append("prizePicture", this.file);
              };

                this.$api
                    .prizeUpdate(formData)
                    .then(res => {
                        if (res.data.code == 200) {

                            this.$message.success("修改成功");
                            this.appList();
                          this.value = "";
                          this.file = {}
                            this.editDialog = false
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            handleChange(file, fileList) {
                console.log(file.raw, file);
                this.value = file.url;
                this.file = file.raw;
                if (file.raw) {
                    this.$nextTick(() => { //等待照片上传完成，
                        this.$refs['formImg'].clearValidate()
                    })

                }
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleRemove(file, fileList) {
                // console.log(file, fileList);
                // this.fileList = fileList
                this.value = "";
                this.file = {};
            },
            //删除函数
            handleDelete(index, row) {
                this.deleteId = row.id;
            },
            //删除确定
            deleteAddHandler() {
                this.$api.prizeDelete(this.deleteId).then(res => {
                    console.log(res.data);
                    if (res.data.code == 200) {
                        this.$message.success("删除成功!");
                        this.appList();
                    }
                });
            },


            //重置函数
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            appList() {
                this.$api
                    .prizeList({
                        page: {
                            current: this.current,
                            size: 10,
                        },
                          queryDto: {
                           prizeName:this.prizeName
          }
                    })
                    .then(res => {
                        // console.log(res.data);
                        for (let index = 0; index < res.data.data.records.length; index++) {
                            res.data.data.records[index].iseditor = false

                        }
                        this.tableData = res.data.data.records;

                        this.total = res.data.data.total;
                    });
            },
            addUserHandler() {
                this.file = {};
                this.addDialogFormVisible = true;
                if (this.$refs["addRules"] !== undefined) {
                    this.resetForm("addRules");
                }
            },
            deleteFn(item) {
                console.log(item)
                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i] == item) {
                        this.items.splice(i, 1)
                    }
                }
            },
            changeCurrent(id) {
                this.current = id;
                this.appList();
            },

            changeAppType(id) {
                // console.log(id);
                this.addappType = Number(id);
            },


            addAppListForm(formName) {
                console.log(this.addForm, this.file)

                this.$refs["addRules"].validate(valid => {
                    this.addForm.prizePicture = this.file
                    let formData = new FormData();
                    formData.append("prizeType", this.addForm.prizeType);
                    formData.append("prizeName", this.addForm.prizeName);
                    formData.append("couponDiscountType", this.addForm.couponDiscountType);
                    formData.append("couponFullPrice", this.addForm.couponFullPrice);
                    formData.append("couponMinusPrice", this.addForm.couponMinusPrice);
                    formData.append("prizePicture", this.addForm.prizePicture);
                    formData.append("prizeStock", this.addForm.prizeStock);
                    formData.append("details", this.addForm.details);
                  formData.append("relateGoodsId", this.id);
                    if (valid) {

                        if (this.addForm.prizeType == 2) {
                            if (this.addForm.couponMinusPrice == '' || this.addForm.couponFullPrice == '' ||
                                this.addForm.couponDiscountType == '') {
                                this.$message.error('请填写优惠券相关数据');
                                return
                            }
                        }
                        this.$api.prizeAdd(formData).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("创建奖品成功！");
                                this.file = {};
                                this.value = ''
                                this.addDialogFormVisible = false;
                                this.appList();
                            }
                        });

                    } else {
                        return false;
                    }
                });
            },





        }
    };
</script>
<style lang="less" scoped>
    @border-bottom-color: #e7eaed;
    @toolBarFontColor: #111d;

    .outting {
        height: 100%;
        overflow: hidden;
        background-color: #fafafa;

        .new_add {
            width: 100%;
        }

        .select-button {
            float: right;
            color: @toolBarFontColor;
            margin-top: 3px;
            margin-left: 15px;
        }

        .inputlist {
            display: inline-block;
            float: right;
            color: @toolBarFontColor;
            margin-top: 8px;
            margin-left: 15px;
        }

        .select {
            height: 46px;
            overflow: hidden;
            border-bottom: 1px solid #d9d9d9;
            padding: 0rem 3rem;

            .select-status {
                width: 9rem;
                margin-left: 1.7rem;
                margin-top: 7px;
                // margin-top:7px;
            }

            .select-input-timer {
                float: right;
            }

            .select-status {
                float: right;
            }

            .select-input-right-input {
                float: right;
                // width: 8rem;
                line-height: 46px;
            }

            .title {
                display: inline-block;
                line-height: 46px;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .addRoleButton {
                margin-left: 15px;
            }

            button {
                float: right;
                color: @toolBarFontColor;
                margin-top: 7px;
            }

            .update-input-timer {
                float: right;
                line-height: 46px;
                margin-left: 25px;
                height: 46px;

                .demonstrationTwo {
                    float: left;
                    font-size: 0.8rem;
                }

                .update-input-timer-select {
                    width: 11rem;
                    margin-left: 5px;
                }
            }
        }

        .table-vv {
            padding: 1rem 3rem;

            .pagin {
                margin-left: 30%;
                margin-top: 10px;
                float: right;
            }
        }
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
